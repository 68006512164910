<template>
  <div class="container" style="padding-top: 100px">
    <System_Manager v-bind:namespace="namespace" />

  </div>

</template>

<script>

import System_Manager from "@/components/System_Manager";
export default {
  name: "SystemView, System_Manager",
  props: {
    wpName: String
  },
  data() {
    return{
      projectNo: null,    namespace : (this.$parent.$auth.user)
    };
  },
  components: {System_Manager}
}
</script>

<style scoped>

</style>