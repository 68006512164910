<template>
<!--  <div class="next-steps">-->
   <div class="container">
   <div  v-if="$auth.isAuthenticated"><h2 class="my-5 text-center">WELCOME TO COSTIMIZER® COLLAB, YOU ARE LOGGED IN!</h2></div>


     <div class="card text-center">
       <div class="card-header">
         NEWS
       </div>
       <div class="card-body">
         <h5 class="card-title text-danger">Cost Roll up finished (90%</h5>
         <p class="card-text">The Cost roll up based on client specification is implemented to 90% now. Following updates will be integraded on 14.6.:   real Material Cost
         (currently all Materials are set to 1,5€/kg),  ad adding estimated part cst to roll up. Further information following.</p>
         <a href="#" class="btn btn-info">IMPORTANT INFORMATION</a>
       </div>
       <div class="card-footer text-muted">
         10th June 2022
       </div>
     </div>


     <div class="card text-center">
       <div class="card-header">
         NEWS
       </div>
       <div class="card-body">
         <h5 class="card-title text-danger">Copy-Paste for Equipment, Material, Commodities, Processes & PARTS</h5>
         <p class="card-text">For copying we have introduced a clip board feature to copy and paste parts, process,equipment, material commodities to
           any place in the same workpackages or even in other workpackages. The function will be released for parts soon incl. a short tutorial</p>
         <p class="card-text text-info"><strong>Important:</strong>Copy and paste of Processes and Parts will take all commodities, material and equipment below into the new past location.
         This makes is easy to reuse part calculations.  Editing the Part Name will be the only action needed after pasting.</p>
         <a href="#" class="btn btn-primary">More Information to other features will follow</a>
       </div>
       <div class="card-footer text-muted">
         10th June 2021
       </div>
     </div>




    <h2 class="my-5 text-center">Basic Information</h2>
    <div class="row">
      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/connections">
            <font-awesome-icon icon="link" class="mr-2" />Workpackages and Calculation Process
          </a>
        </h6>
        <p>The COSTIMIZER® collaborative cost analysis tool allows a remote work on assigned workpackages based on a workflow within distributed teams. </p>
      </div>

      <div class="col-md"></div>

      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/multifactor-authentication">
            <font-awesome-icon icon="link" class="mr-2" />Tutorial for collaborative cost analysis
          </a>
        </h6>
        <p>The tutorial guides through the main steps how to apply for workpackages, and how to create a cost model step by step. If you want to get access to the totorial you have to be assigned to a project.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/anomaly-detection">
            <font-awesome-icon icon="link" class="mr-2" />Information to the current version
          </a>
        </h6>
        <p>The COSTIMIZER® Collaboration Tool is released in the stable version 1.0153 (2021), it provided the the based functionalities (frontend/backend) to create cost analysis models.</p>
      </div>

      <div class="col-md"></div>

      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/rules">
            <font-awesome-icon icon="link" class="mr-2" />Apply for a project
          </a>
        </h6>
        <p>Pleas get in contact to us, if you want to be part of the COSTIMIZER® Collaboration Network and to apply for projects.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContent"
};
</script>
