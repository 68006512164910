<template>
<div>


       <div class="accordion accordion-flush" :id="compUID"  >
            <div class="accordion-item" >
              <div class="row justify-content-start">
                <div class="col-10">
                    <h2 class="accordion-header" :id="mhcompUID" >
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID" style="background-color: #92D050; color: #5A5C4F">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="margin-right:10px" fill="currentColor" class="bi bi-wrench" viewBox="0 0 16 16">
                          <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z"/>
                        </svg>
                        Machines and Equipment [<strong>Sum of Equipment Costs:  {{totalEquipmentCost}}€</strong>, @ Process Time:  {{procProcTime}}{{procProcTimeUnit}}]
                      </button>
                    </h2>
                </div>
                <div class="col-2 my-auto" style="display:flex; justify-content: space-between; ">
                  <button v-if="userRole!= 'client' && wpStatus == 'working'"  type="button" class="btn btn-link btn-sm" @click="openModelEquipment(procID)" value="Add">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                  </button>
                  <button v-if="$store.state.clipboard.equipmentID != '' && userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="pasteEquipment($store.state.clipboard.equipmentID)" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Paste Equipment">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                    </svg>
                  </button>
                </div>
              </div>


              <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID" >
                <div  class="accordion-body" v-for="row in allDataEquipment" v-bind:key="row" :id="row.equipmentID">
                  <div class="row justify-content-start"  >
                    <div class="col-10" style="background-color: #D0EBB3; color: #5A5C4F">
                  Equipment :  {{row.equipmentName}}  (Technology: {{row.equipmentType}}) <br><strong>Hourly Rate (MHR) {{row.equipmentCost}}€</strong>
                    </div>
                     <div class="col-2 my-auto" >
                      <button  v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')" type="button" class="btn btn-link btn-sm" @click="fetchDataEquipment(row.equipmentID, row.UPPID)" value="Edit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </button>

                    <button v-if="$store.state.clipboard.equipmentID != row.equipmentID &&  userRole!= 'client'"  type="button" class="btn btn-link btn-sm"  @click="clipEquipment(row.equipmentID, row.equipmentName)" value="Copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Equipment">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                      </svg>
                    </button>

                    <button v-if="$store.state.clipboard.equipmentID == row.equipmentID &&  userRole!= 'client'" type="button" class="btn btn-link btn-sm"    value="Clipped" data-bs-toggle="tooltip" data-bs-placement="top" title="Equipment in clipboard for paste">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="coral" class="bi bi-app-indicator" viewBox="0 0 16 16">
                        <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                        <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                      </svg>
                    </button>


                      <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="deleteDataEquipment(row.equipmentID)" value="Delete">
                        <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>

  <div  v-if="myModel" >
    <transition name="model" >
      <div class="modal-mask"  >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">

                <div class="form-group">
                  <label>Select Search Type for Equipment  / Or Create New Equipment
                  </label>
                  <div class="form-check" >
                    <input class="form-check-input" name="searchType" type="radio" value="tree" id="tree"  v-model="searchType" @change="resetAutocomplete">
                    <label class="form-check-label" for="tree">
                      Search in Tree
                    </label>
                  </div>
                  <div class="form-check" >
                    <input class="form-check-input" name="searchType" type="radio" value="text" id="text"  v-model="searchType" @change="resetAutocomplete">
                    <label class="form-check-label" for="text">
                     Search by Text
                    </label>
                  </div>
                  <div class="form-check" >
                    <input class="form-check-input" name="searchType" type="radio" value="searchOwn" id="searchOwn"  v-model="searchType" @change="resetAutocomplete">
                    <label class="form-check-label" for="searchOwn">
                      Search in Own Equipment
                    </label>
                  </div>
                 <div v-if="dynamicTitle == 'Add Equipment'" class="form-check" >
                    <input class="form-check-input" name="searchType" type="radio" value="newEquip" id="newEquip"  v-model="searchType" >
                    <label class="form-check-label" for="newEquip" style="color:blue">
                      Create New Equipment
                    </label>
                  </div>

                  <div id="treeSearch" v-if="searchType ==='tree'" style="margin-top: 20px; font-size: small">
                        <div class="form-group">
                          <label>Search in equipment reference tree
                          </label>
                        <treeselect v-model="equipmentSelection1"
                                    name="treeEquipmentType"
                                    :multiple="false"
                                    :options="optionsEquipmentTree"
                                    :disable-branch-nodes="true"
                                    search-nested
                        />
                      </div>
                  </div>


                    <div id="textSearch" v-if="searchType ==='text'" style="margin-top: 20px">
                      <div class="form-group">
                        <label>Search and select Equipment
                        </label>
                        <autocompleteEquip  id="equipSearchAC" :key="autocompleteKey" :search="searchReference"
                                            placeholder="Search you Equipment here"
                                            auto-select @submit="handleSubmit"
                        />
                      </div>
                    </div>

                    <div id="ownEquipSearch" v-if="searchType ==='searchOwn'" style="margin-top: 20px">
                      <div class="form-group">
                        <label>Search and select in Own Equipment
                        </label>
                        <autocompleteEquip  id="equipSearchAC2" :key="autocompleteKey" :search="searchOwn"
                                            placeholder="Search your OWN Equipment here"
                                            auto-select @submit="handleSubmit"
                        />
                      </div>
                    </div>


                  <div id="createEquip" v-if="searchType ==='newEquip'" style="margin-top: 20px; font-size: xx-small">

                    <div>
                      <div v-if="!successAlert && previewImage"
                           class="imagePreviewWrapper"
                           :style="{ 'background-image': `url(${previewImage})` }"
                           @click="selectImage"  >
                      </div>

                      <div v-if="successAlert && previewImage"
                           class="imagePreviewWrapper"
                           :style="{ 'background-image': `url(${previewImage})` }"
                      >
                      </div>


                      <input v-if="!successAlert"
                             ref="file"
                             type="file"
                             id = "file"
                             v-on:change="handleFileUpload()"
                             @input="pickFile"  />
                    </div>
                    <button v-if="!successAlert" v-on:click="submitFile()">SAVE PICTURE</button>


                       <div class="input-group mb-3" style="margin-top: 20px">
                         <span class="input-group-text">Equipment Name</span>
                        <input type="text" class="form-control input-sm" v-model="equipmentName" required/>
                      </div>
<!--                       <div class="input-group mb-3" style="margin-top: 20px">
                         <span class="input-group-text">Equipment Model </span>
                        <input type="text" class="form-control input-sm" v-model="equipmentModel" placeholder="(e.g. Type Name or Number), optional"/>
                      </div>-->
                       <div class="input-group mb-3" style="margin-top: 20px">
                         <span class="input-group-text">Equipment Description </span>
                        <textarea  class="form-control input-sm" v-model="equipmentDesc" required/>
                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Equipment Type </span>
                        <textarea  class="form-control input-sm" v-model="equipmentType" required placeholder="e.g. Welding Station, Painting"/>
                      </div>
                       <div class="input-group mb-3" style="margin-top: 20px">
                         <span class="input-group-text">Equipment Vendor </span>
                        <input type="text" min="1" max="999" step="1" class="form-control input-sm" v-model="equipmentVendor" />
                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Floor Area (Equipment + Workspace)</span>
                        <input type="number" class="form-control input-sm"   step="0.1" v-model="equipmentFloor" required />
                        <span class="input-group-text">sqm</span>
                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Nominal Power</span>
                        <input type="number" class="form-control input-sm"   step="1" v-model="equipmentPower" required />
                        <span class="input-group-text">kwH</span>
                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Average Load (for Power consumption)</span>
                        <input type="number" class="form-control input-sm"   step="1" v-model="equipmentPowerrate" required />
                        <span class="input-group-text">%</span>
                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Total Investment</span>
                        <input type="number" step="1"  class="form-control input-sm"    v-model="equipmentInvestment" required />
                        <span class="input-group-text">€</span>
                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Properties</span><br>
                      <textarea  class="form-control input-sm "   step="1" v-model="equipmentProperties"  placeholder="  (e.g. Clamping Size or Forces), Optional" />


                    </div>

                      <br />

                  </div>

                <div class="form-group">
                  <label>Remark to Equipment</label>
                  <input type="text" class="form-control" v-model="equipmentRemark" />
                </div>
                <br />
                <div align="center">
                  <input type="hidden"   v-model="procID"  :placeholder="procID">
                  <input type="hidden"   :value="equipmentID" v-model="equipmentID">
                  <input type="hidden" v-model="hiddenId" />
                  <input v-if="userRole!= 'qamanager'" type="submit" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataEquipment" />
                </div>
              </div>
            </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>
          </div>
        </div>
      </div>
      </div>

    </transition>
  </div>





  <div  v-if="myModelEdit" >
    <transition name="model" >
      <div class="modal-mask"  >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModelEdit=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">



                  <div id="editEquip" style="margin-top: 20px; font-size: xx-small">



                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Equipment Name</span>
                      <input type="text" class="form-control input-sm" v-model="equipmentName" required/>
                    </div>
<!--                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Equipment Model </span>
                      <input type="text" class="form-control input-sm" v-model="equipmentModel" placeholder="(e.g. Type Name or Number), optional"/>
                    </div>-->
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Equipment Description </span>
                      <textarea  class="form-control input-sm" v-model="equipmentDesc" required/>
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Equipment Type </span>
                      <textarea  class="form-control input-sm" v-model="equipmentType" required placeholder="e.g. Welding Station, Painting"/>
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Equipment Vendor </span>
                      <input type="text" min="1" max="999" step="1" class="form-control input-sm" v-model="equipmentVendor" />
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Floor Area (Equipment + Workspace)</span>
                      <input type="number" class="form-control input-sm"   step="0.1" v-model="equipmentFloor" required />
                      <span class="input-group-text">sqm</span>
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Nominal Power</span>
                      <input type="number" class="form-control input-sm"   step="1" v-model="equipmentPower" required />
                      <span class="input-group-text">kwH</span>
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Average Load (for Power consumption)</span>
                      <input type="number" class="form-control input-sm"   step="1" v-model="equipmentPowerrate" required />
                      <span class="input-group-text">%</span>
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Total Investment</span>
                      <input type="number" step="1"  class="form-control input-sm"    v-model="equipmentInvestment" required />
                      <span class="input-group-text">€</span>
                    </div>
                    <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Properties</span><br>
                      <textarea  class="form-control input-sm "   step="1" v-model="equipmentProperties"  placeholder="  (e.g. Clamping Size or Forces), Optional" />


                    </div>

                    <br />

                  </div>



                  <div class="form-group">
                    <label>Remark to Equipment</label>
                    <input type="text" class="form-control" v-model="equipmentRemark" />
                  </div>
                  <br />
                  <div align="center">
                    <input type="hidden"   v-model="procID"  :placeholder="procID">
                    <input type="hidden"   :value="equipmentID" v-model="equipmentID" :placeholder="equipmentID" readonly>
                    <input type="hidden" v-model="hiddenId" />
                    <input type="hidden"   v-model="equipmentFamily"><br>
                    <input type="hidden"   :value="UPPID" v-model="UPPID" :placeholder="UPPID" readonly><br>
                    <input  v-if="userRole!= 'qamanager'" type="submit" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataEquipment" />
                  </div>
                </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>
              </div>
            </div>
          </div>
        </div>


    </transition>
  </div>





</div>
  </template>

<script>


const axios = require('axios').default;
// axios.<method> will now provide autocomplete and parameter typings

import { v4 as uuidv4 } from 'uuid';
// import the component

import AutocompleteEquip from '@trevoreyre/autocomplete-vue'
// import the style
import '@trevoreyre/autocomplete-vue/dist/style.css'

import equipments from '../assets/datalists/equipment.json'

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the style
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
// AR this  might not be necessary anymore (pls Check 22-08-09)
let equipmentTypes = require ('../assets/datalists/equipment_tree.json');



export  default {
  name: "Equipment",
  components: {AutocompleteEquip, Treeselect},
  props: {
    procID: String,
    supplierLocation: String,
    procProcTime: String,
    procProcTimeUnit: String,
    procCavityQty: Number,
    procRejectRate: Number,
    procSetupTime: Number,
    procSetupUnit: String,
    wpStatus: String,
    namespace: Array,

  },

  data: function () {
    return {
       allDataEquipment:{}, myModel: false, myModelEdit: false, actionButton: 'Insert', dynamicTitle: 'Equipment', WP_ID: '', WP_Name: '',
      projectNo:this.$route.query.projectno, wpID: this.$route.query.wpID, compUID: '', hcompUID :'' , mcompUID:'', mhcompUID:'', mchcompUID:'', mccompUID:'',
      equipmentType:'', equipmentDesc:'', equipmentName: '', equipmentRemark: '', equipmentID: '', equipmentVendor: '', equipmentURL:'',equipmentSelection1: null, equipmentSelection2:'',
      treeEquipmentType: null, searchType: '', options2:'',optionsEquipmentTree:'',  equipmentFloor:'', equipmentPower:'', equipmentPowerrate:'', equipmentInvestment:'', ownEquipmentList: {},
      equipmentProperties:'', equipmentModel:'', equipmentCost:0, UPPID:'', equipmentFamily:'',
      options: equipmentTypes, userRole : this.namespace['https://www.costimizer.de/role'],
      mouseY: 0
    };
  },



  methods: {
    //Rest Autocomplete (Force Reload)

    resetAutocomplete() {
      this.autocompleteKey += 1;
    },


    // Get Equipment List TREE
    getEquipmentTree()  {
      let self = this
      axios.post('/src/php/costimizer_collab_equipmenttree.php', {
        action: 'getEquipmentTree',
        ceID: this.$parent.$auth.user.sub

      }).then((response) => {
       self.optionsEquipmentTree = response.data;
      //  console.log(response.data);
    });
    },


    // Get Reference Equipment List  [NOT USED: insted Assset / equipment.json
    getEquipmentlist()  {
      console.log ('USER ID'+ this.$parent.$auth.user.sub)
      let self = this
      axios.post('/src/php/costimizer_collab_equipmentlist.php', {
        action: 'getEquipmentList',
        ceID: this.$parent.$auth.user.sub

      }).then((response) => {
        self.options3 = response.data;
         //   console.log(response.data);
      });
    },


    // Get Own Equipment List
    getOwnEquipmentlist()  {
      let self = this
      axios.post('/src/php/costimizer_collab_ownequipmentlist.php', {
        action: 'getOwnEquipmentList',
        ceID: this.$parent.$auth.user.sub

      }).then((response) => {

        self.ownEquipmentList = response.data;
       // console.log (self.ownEquipmentList);
      });
    },

    //  GET EQUIPMENT TYPES
    searchOwn(input) {
      if (input.length < 1) { return [] }
      return this.ownEquipmentList.filter(equipment => {
        //  IF STATEMENT FOR ADDITONAL  SERACH LIKE FOR MACHINES
        // if (equipment.toLowerCase().includes(input.toLowerCase())) {
          return equipment.toLowerCase()
              .includes(input.toLowerCase())
      /*  }
        else {
          return []
        }*/
      })
    },

    searchReference(input) {
      if (input.length < 1) { return [] }
      return equipments.filter(equipment => {
        return equipment.toLowerCase()
            .includes(input.toLowerCase())

     // return this.$store.state.ownEquip.filter(equipment => {
        //  IF STATEMENT FOR ADDITONAL  SERACH LIKE FOR MACHINES
        // if (equipment.toLowerCase().includes(input.toLowerCase())) {
      //  return equipment.toLowerCase()
        //    .includes(input.toLowerCase())
        /*  }
          else {
            return []
          }*/
      })
    },

    handleSubmit(result) {
      alert(`You have selected ${result}`);
      this.equipmentSelection2 = result;
      this.equipmentSelection1 = '';
      this.autocompleteKey += 1;
    },



   /// PROCESS IMAGE
    // SELECT IMAGE
    selectImage () {
      this.$refs.file.click()
    },
    pickFile () {
      let input = this.$refs.file
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    //  Show Pic on Mouseover as Modal

    showImageModal: function(imgName, partName) {
      this.dynamicTitlePic = partName;
      this.partPath = imgName;
      this.myModelPic = true;
    },
    closeImageModal: function() {

      this.myModelPic = false;
    },


    //  Save IMAGE
    submitFile(){
      this.file = this.$refs.file.files[0];

      var formData = new FormData();

      formData.append('file', this.file);

      axios.post('./src/php/costimizer_collab_image_upload.php', formData, {
        header:{
          'Content-Type' : 'multipart/form-data'
        }
      }).then((response)=>{
        if(response.data.image == '')
        {
          this.errorAlert = true;
          this.successAlert = false;
          this.errorMessage = response.data.message;
          this.successMessage = '';
          this.uploadedImage = '';
        }
        else
        {
          this.errorAlert = false;
          this.successAlert = true;
          this.errorMessage = '';
          this.successMessage = response.data.message;
          let image_html = "<img src='"+response.data.image+"' class='img-thumbnail' width='200' />";
          this.imgName = response.data.image;
          this.uploadedImage = image_html;
          this.$refs.file.value = '';
        }
      });
    },

    /*
      Handles a change on the file upload
    */
    handleFileUpload(){
      this.file = this.$refs.file.files[0];

    },




    //  MANAGE Equipment
    fetchAllDataEquipment: function () {

      axios.post('/src/php/costimizer_collab_manage_equipment.php', {
        action: 'fetchallEquipment', projectNo: this.$route.query.projectno, wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        procID: this.procID,
        SupplierLocation: this.SupplierLocation,
        procProcTime: this.procProcTime,
        procProcTimeUnit: this.procProcTimeUnit,
        procCavityQty: this.procCavityQty,
        procRejectRate: this.procRejectRate,
        procRejectUnit: this.procRejectUnit,
        procSetupTime: this.procSetupTime,
        procSetupUnit: this.procSetupUnit,
        userRole : this.$route.query.userRole

      }).then((response) => {
        this.allDataEquipment = response.data;


        // send Data to vuex stage


        let procTime = 0
        let setupTime =  0
        let procCavity = 0

        // TASK  BATCH VARIALBE FROM Store > Later
        if (this.procCavityQty < 1) {this.procCavityQty =1}
        procCavity = this.procCavityQty
        setupTime = this.procSetupTime/(60*75000)
        procTime  = this.procProcTime
        if (this.procProcTimeUnit == "s"   || this.procProcTimeUnit == "" )
        {
          procTime = this.procProcTime /3600;
        }
        if (this.procProcTimeUnit == "min")
        {
          procTime = this.procProcTime / 60;
        }
        if (this.procProcTimeUnit == "h")
        {
          procTime = this.procProcTime ;
        }
    //    console.log ('apr time unit:'+this.procProcTimeUnit)
        if (this.allDataEquipment.length > 0 ) {
          let costSum2 = 0


          costSum2 = this.allDataEquipment.reduce(function (prev, cur) {
            return Math.round((prev + cur.equipmentCost * (procTime+ setupTime ) /procCavity) * 100) / 100;
          }, 0);


          // Delete old entries



            if (costSum2 != 0 ) {
              this.$store.commit('addEquip', {
                equipmentID: '',
                procID: this.procID,
                equipmentCost: costSum2
              });
            }
        }


      });
    },

    openModelEquipment: function () {
      this.equipmentName = '';
      this.equipmentDesc = '';
      this.equipmentType = '';
      this.equipmentFamily = '';
      this.equipmentURL = '';
      this.equipmentVendor = '';
      this.equipmentRemark = '';
      this.equipmentFloor = '';
      this.equipmentPower = '';
      this.equipmentPowerrate = '';
      this.equipmentInvestment = '';
      this.equipmentProperties = '';
      this.actionButton = "Save";
      this.dynamicTitle = "Add Equipment";
      this.mouseY = event.clientY+'px';
      this.myModel = true;


    },
    submitDataEquipment: function () {
      if (this.equipmentSelection1 != '' || this.equipmentSelection2 != '') {
       //  alert(`selected: ${this.equipmentSelection1}`);
        if (this.actionButton == 'Save') {
          axios.post('/src/php/costimizer_collab_manage_equipment.php', {
            action: 'insertEquipment',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            wpHash: this.$route.query.wpHash,
            ceID: this.$parent.$auth.user.sub,
            procID: this.procID,
            equipmentID: this.equipmentSelection1,  // hidden  come from Tree Search//
            equipmentName: this.equipmentName,
            equipmentDesc: this.equipmentSelection2,  // hidden  come from auto complete search//
            equipmentType: this.equipmentType,  // hidden //
            equipmentFamily: this.searchType,  // hidden //
            equipmentURL: this.equipmentURL,  // hidden //
            equipmentVendor: this.equipmentVendor,   // hidden //
            equipmentRemark: this.equipmentRemark,
            equipmentFloor: this.equipmentFloor,
            equipmentPower: this.equipmentPower,
            equipmentPowerrate: this.equipmentPowerrate,
            equipmentInvestment: this.equipmentInvestment,
            equipmentProperties: this.equipmentProperties,
            searchType: this.searchType




          }).then( (response)=>{
            this.myModel = false;
            this.fetchAllDataEquipment();
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if (this.actionButton == 'Update') {
          axios.post('/src/php/costimizer_collab_manage_equipment.php', {
            action: 'updateEquipment',
            wpID: this.wpID,
            projectNo: this.$route.query.projectNo,
            procID: this.procID,
            ceID: this.$parent.$auth.user.sub,
            equipmentID: this.equipmentID,
            UPPID: this.UPPID,
            equipmentName: this.equipmentName,
            equipmentDesc: this.equipmentDesc,
            equipmentType: this.equipmentType,
            equipmentFamily: this.equipmentFamily,
            equipmentVendor: this.equipmentVendor,
            equipmentURL: this.equipmentURL,
            equipmentRemark: this.equipmentRemark,
            equipmentFloor: this.equipmentFloor,
            equipmentPower: this.equipmentPower,
            equipmentPowerrate: this.equipmentPowerrate,
            equipmentInvestment: this.equipmentInvestment,
            equipmentProperties: this.equipmentProperties
          }).then((response)=>{
            this.myModelEdit = false;
            this.fetchAllDataEquipment();
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Fill All Field");
      }
    },
    fetchDataEquipment: function (equipmentID,UPPID) {
      let self = this
      axios.post('/src/php/costimizer_collab_manage_equipment.php', {
        action: 'fetchSingleEquipment',
        equipmentID: equipmentID,
        UPPID: UPPID
      }).then( (response)=> {
        self.equipmentID = response.data.equipmentID;
        self.UPPID = response.data.UPPID;
        self.equipmentDesc = response.data.equipmentDesc;
        self.equipmentName = response.data.equipmentName;
        self.equipmentType = response.data.equipmentType;
        self.equipmentFamily = response.data.equipmentFamily;
        self.equipmentVendor = response.data.equipmentVendor;
        self.equipmentURL = response.data.equipmentURL;
        self.equipmentRemark = response.data.equipmentRemark;
        self.equipmentFloor = response.data.equipmentFloor;
        self.equipmentPower = response.data.equipmentPower;
        self.equipmentPowerrate = response.data.equipmentPowerrate*100;
        self.equipmentInvestment = response.data.equipmentInvestment;
        self.equipmentProperties = response.data.equipmentProperties;
        self.myModelEdit = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Equipment';
      });
    },
    deleteDataEquipment: function (equipmentID) {
      if (confirm("Are you sure you want to remove this data?")) {
        axios.post('/src/php/costimizer_collab_manage_equipment.php', {
          action: 'deleteEquipment',
          equipmentID: equipmentID
        }).then( (response) =>{
          this.fetchAllDataEquipment();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },

    // ================ COPY PASTE METHOD  ===============================
    clipEquipment: function (equipmentID, equipmentName) {
      this.$store.state.clipboard.equipmentID = equipmentID;
      this.$store.state.clipboard.equipmentName = equipmentName;
      // alert('Copied');
      // Add clipPart to clip DB
      axios.post('/src/php/costimizer_collab_manage_clipboard.php', {
        action: 'clipEquipment',
        ceID: this.$parent.$auth.user.sub,
        userRole: this.userRole,
        clippedEquipmentID: equipmentID,
        clippedEquipmentName: equipmentName
      }).then((response) => {
        if ( response.data.message ) {
         // alert(response.data.message);
          response.data.message = '';
        }
      });

    },


    pasteEquipment: function (equipmentID) {


      axios.post('/src/php/costimizer_collab_manage_equipment.php', {
        action: 'pasteEquipment',
        equipmentID: equipmentID,
        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        procID: this.procID,
        wpHash: this.$route.query.wpHash


      }).then((response) => {
        parent.myModel = false;
        this.fetchAllDataEquipment();
        // this.commodityName = '';
        // Alert to replace by Toast
       if ( response.data.message ) {
          // alert(response.data.message);
          response.data.message = '';
        }
      });
    },

  },





  created:function(){
    this.compUID = uuidv4();
    this.compUID = this.compUID.replace(/-/g,'');
    this.compUID = 'e'+this.compUID;
    this.compUID = this.compUID.substring(0,10);
    this.hcompUID = '#'+this.compUID;
    this.mhcompUID = 'mh'+this.compUID;
    this.mchcompUID = '#mc'+this.compUID;
    this.mccompUID = 'mc'+this.compUID;
    this.fetchAllDataEquipment();
    this.getEquipmentlist();
    this.getEquipmentTree();
    this.getOwnEquipmentlist();
    this.$forceUpdate();
  },

  computed: {

 totalEquipmentCost () {
  // SEND  EQUIPMENT COST TO VUEX
      let costSum = 0
      let procTime = 0
       procTime  = this.procProcTime
      if (this.procProcTimeUnit == "s"   || this.procProcTimeUnit == "" )
      {
        procTime = this.procProcTime / 3600 ;
      }
      if (this.procProcTimeUnit == "min")
      {
        procTime = this.procProcTime / 60;
      }
   if (this.procProcTimeUnit == "h")
   {
     procTime = this.procProcTime;
   }
      // return this.numbers.reduce((sum, val)=> sum + val);
      if (this.allDataEquipment.length > 0 ) {
        costSum = this.allDataEquipment.reduce(function (prev, cur) {
          return Math.round((prev + cur.equipmentCost*procTime)*100)/100;
        }, 0);
        this.$emit('equipmentToAssembly', costSum);

        return (costSum);

      }
      else {
        return 0
      }
    }
  }

}
</script>

<style scoped>

.accordion {
  padding-left: 10px;
}


.accordion-header {
  background-color: #92D050;
  color: #E7E9DF;
  padding-left: 10px;
}
.accordion-button  {
  background-color: #92D050;
  color: #E7E9DF;
  padding-top:5px;
  padding-bottom:5px;
}

table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}



.modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  font-size: small;
}


.modal-body {
  font-size: small;
}
.input-group {
  font-size: small;
}



</style>