<template>
  <div>


    <div class="accordion accordion-flush" :id="compUID"  >
      <div class="accordion-item">
        <div class="row justify-content-start">
          <div class="col-11" >
            <h2 class="accordion-header" :id="mhcompUID">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID"  style="background-color: #004D6C; color: #E7E9DF">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  style="margin-right:10px" fill="currentColor" class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z"/>
                </svg>
                Assembly Processes  Current Cost: <strong>{{sumAssyProcCost}}€</strong> (incl. Overhead)
              </button>
            </h2>
          </div>
          <div class="col-1 my-auto" >
            <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="openModelAssyproc(partID)" value="Add">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </button>
            <button v-if="$store.state.clipboard.assyprocessID != '' && userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="pasteAssyProcess($store.state.clipboard.assyprocessID)" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Paste Equipment">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
            </button>
          </div>
        </div>


        <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID">


          <div class="accordion accordion-flush" v-for="row in allDataAssyproc" v-bind:key="row" :id="row.assyprocID">
            <div class="accordion-item">
              <div class="row justify-content-start">
                <div class="col-10">
                  <h2 class="accordion-header" :id="'flush'+row.assyprocID">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+row.assyprocID" aria-expanded="false" :aria-controls="'flush-collapse'+row.assyprocID" style="background-color: #0076A3; color: #E7E9DF">
                      {{row.assyprocName}}  [Cycle Time:  {{row.assyprocProcTime}}{{row.assyprocProcTimeUnit}}, Location: {{row.assySupplierLocation}},  <strong style="color:white">
                      ProcessCost: {{row.assyProcessCost}} €</strong>(incl. Overhead)
                    </button>
                  </h2>
                </div>
                <div class="col-2 my-auto"  >
                  <button  v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')"  type="button" class="btn btn-link btn-sm" @click="fetchDataAssyproc(row.assyprocID)" value="Edit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </button>

                  <button v-if="$store.state.clipboard.assyprocessID != row.assyprocID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"  @click="clipAssyProcess(row.assyprocID, row.assyprocName)" value="Copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Assembly Process">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                    </svg>
                  </button>

                  <button v-if="$store.state.clipboard.assyprocessID == row.assyprocID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"    value="Clipped" data-bs-toggle="tooltip" data-bs-placement="top" title="Assembly Process in clipboard for paste">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="coral" class="bi bi-app-indicator" viewBox="0 0 16 16">
                      <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                      <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    </svg>
                  </button>


                  <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="deleteDataAssyproc(row.assyprocID)" value="Delete">
                    <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>
                </div>
              </div>
              <div :id="'flush-collapse'+row.assyprocID" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+row.assyprocID" :data-bs-parent="'#'+row.assyprocID">
                <Equipment v-bind:procID="row.assyprocID"  v-bind:supplierLocation="row.assySupplierLocation"
                           v-bind:procProcTime="row.assyprocProcTime"
                           v-bind:procProcTimeUnit="row.assyprocProcTimeUnit"
                           v-bind:procCavityQty="row.assyprocCavityQty"
                           v-bind:procRejectRate="row.assyprocRejectRate"
                           v-bind:procRejectUnit="row.assyprocRejectUnit"
                           v-bind:procSetupTime="row.assyprocSetupTime"
                           v-bind:procSetupUnit="row.assyprocSetupUnit"
                           @hook:created="getAssyProcessCost"
                           v-bind:wpStatus="wpStatus"
                           v-bind:namespace="namespace"
                />
                <Commodities v-bind:procID="row.assyprocID"   @hook:created="getAssyProcessCost"  v-bind:wpStatus="wpStatus"  v-bind:namespace="namespace"/>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div v-if="myModel">
      <transition name="model">
        <div class="modal-mask" v-bind:style="{top: mouseY}" >
          <div class="modal-wrapper">
            <div class="modal-dialog modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                  <h4 class="modal-title">{{ dynamicTitle }}</h4>
                </div>
                <div class="modal-body">
                  <form class="row g-3 " >
                    <div class="form-group" >
                      <label>Enter Process Name</label>
                      <input type="text" class="form-control"  :value="assyprocName"  v-model="assyprocName" required />
                    </div>
                    <div class="form-group">
                      <label>Enter Supplier Location, current Location:</label>
                      <input type="text" class="form-control" v-model="assySupplierLocation" readonly><br>
                      <autocomplete  id="assySupplierLocation"  ref="autocomplete" :search="searchLocation"  placeholder="Search Location"   default-value="Mexico" @submit="handleSubmit"/>

                    </div>
                    <div class="form-group">
                      <label>Enter Supplier Name</label>
                      <input type="text" class="form-control" v-model="assySupplierName" placeholder="optional"/>
                    </div>

                    <div class="input-group mb-3" >
                      <span class="input-group-text">Process Time</span>
                      <input type="number" class="form-control"   step="0.1"   v-model="assyprocProcTime" :value="assyprocProcTime" required />
                      <span class="input-group-text">
                        <select class="form-select" aria-label="Process Time Unite" v-model="assyprocProcTimeUnit"  :value="assyprocProcTimeUnit" required>
                          <option  value="s">s</option>
                          <option value="min">min</option>
                          <option value="h">h</option>
                        </select>
                      </span>
                    </div>

<!--                    <div class="input-group mb-3" >
                      <span class="input-group-text">No. of Cavities </span>-->
                      <input type="hidden" class="form-control"   min="0" max="500" v-model="assyprocCavityQty"  value="1" />
<!--                      <span class="input-group-text">Parts</span>
                    </div>-->
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Reject Rate </span>
                      <input type="number" class="form-control"  min="0" max="100"  step="0.01" v-model="assyprocRejectRate"  required/>
                      <span class="input-group-text" >
                      <input type="hidden" class="form-control"  size="3"    v-model="assyprocRejectUnit" readonly />
                         <span class="input-group-text">%</span>
                      </span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Setup Time </span>
                      <input type="number" class="form-control"   min="0" max="500"  step="1" v-model="assyprocSetupTime" required />
                      <span class="input-group-text" >
                      <input type="hidden" class="form-control"  size="3"    v-model="assyprocSetupUnit" readonly />
                         <span class="input-group-text">min</span>
                      </span>
                    </div>

                    <div class="input-group mb-3" >
                      <span class="input-group-text">Staff allocation Unskilled Worker</span>
                      <input type="number" class="form-control"  min="0" max="10"  step="0.1" v-model="assyprocUnskilledQty" required />
                      <span class="input-group-text">Persons</span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Staff allocation Skilled Worker</span>
                      <input type="number" class="form-control"   min="0" max="10" step="0.1" v-model="assyprocSkilledQty" required />
                      <span class="input-group-text">Persons</span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Staff allocation Technicians </span>
                      <input type="number" class="form-control" min="0" max="10"   step="0.1" v-model="assyprocTecQty" required />
                      <span class="input-group-text">Persons</span>
                    </div>

                    <div class="form-group" style="margin-top: 20px">
                      <label>Remark to the Process</label>
                      <input type="text" class="form-control" v-model="assyprocRemark" />
                    </div>
                    <br />
                    <div align="center">
                      <input type="hidden"   v-model="partID"  :placeholder="partID">
                      <input type="hidden"   v-model="assyprocID">
                      <input type="hidden" v-model="hiddenId" />
                      <input v-if="userRole!= 'qamanager'" type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataAssyproc" />
                    </div>
                  </form>
                </div>

                <div class="modal-footer">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

import { v4 as uuidv4 } from 'uuid';
import Equipment from "@/components/Equipment";
import Commodities from "@/components/Commodities";

// import the component
import countries from '../assets/datalists/countries.json'
import Autocomplete from '@trevoreyre/autocomplete-vue'

export  default {
  name: "Assemblyproc",
  components: {Equipment, Commodities, Autocomplete},

  props: {
    assyRoot: String,
    subassyID: String,
    wpStatus: String,
    namespace: Array,
  },

  data: function () {
    return {
      allDataAssyproc:{}, myModel: false, actionButton: 'Insert', dynamicTitle: 'Add Data', WP_Name: '',
      projectNo:this.$route.query.projectno, wpID: this.$route.query.wpID, compUID: '', hcompUID :'' , mcompUID:'', mhcompUID:'', mchcompUID:'', mccompUID:'',
      assyprocName: '', assyprocProcTime: '', assyprocProcTimeUnit: '', assyprocCavityQty: '', assyprocRejectRate:'', assyprocRejectUnit:'', assyprocSetupTime: '', assyprocSetupUnit: '', assyprocUnskilledQty: '',
      assyprocSkilledQty: '', assyprocTecQty: '', assyprocRemark: '', assyprocID:'', assySupplierName:'', assySupplierLocation:'',  assyProcessCost: 0, assyProcBaseCost: '',
      assyProcCostSum:0, trigger: 0, userRole : this.namespace['https://www.costimizer.de/role'],
      mouseY: 0
    };
  },



  methods: {


  //   AUTOCOMPLETE COUNTRIES

searchLocation(input) {

  if (input.length < 1) { return [] }
  return countries.filter(countries => {

    return countries.toLowerCase()
        .includes(input.toLowerCase())
  })
},
handleSubmit(result) {
  this.assySupplierLocation = result
},






    //  MANAGE AssypROCESSES
    fetchAllDataAssyproc: function () {

          axios.post('/src/php/costimizer_collab_manage_assyprocess.php', {
        action: 'fetchallAssyproc', projectNo: this.$route.query.projectno, wpID: this.$route.query.wpID, ceID: this.$parent.$auth.user.sub,
        userRole : this.$route.query.userRole,
        partID: this.partID, subassyID:this.subassyID, procTime: this.assyprocProcTime, procProcTimeUnit: this.assyprocProcTimeUnit,  procCavityQty: this.assyprocCavityQty,
        procRejectRate: this.assyprocRejectRate, procRejectUnit:this.assyprocRejectUnit, procSetupTime: this.assyprocSetupTime, procSetupUnit:this.assyprocSetupUnit,
        supplierLocation: this.assySupplierLocation

      }).then((response) => {
        this.allDataAssyproc = response.data;




      });
    },

    openModelAssyproc: function (partID) {
      this.assyprocName = '';
      this.assyprocID = '',
      this.assySupplierName = '',
      this.assySupplierLocation = '',
      this.assyprocProcTime = '';
      this.assyprocProcTimeUnit = '';
      this.assyprocCavityQty = '',
      this.assyprocRejectRate = '',
      this.assyprocRejectUnit = '',
      this.assyprocSetupTime = '',
      this.assyprocSetupUnit = '',
      this.assyprocUnskilledQty = '',
      this.assyprocSkilledQty = '',
      this.assyprocTecQty = '',
      this.assyprocRemark = '',
      this.partID = partID;
      this.actionButton = "Save";
      this.dynamicTitle = "Add Process";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },
    submitDataAssyproc: function () {
      if (this.assyprocName != '') {
        if (this.actionButton == 'Save') {
          this.myModel = false;
          axios.post('/src/php/costimizer_collab_manage_assyprocess.php', {
            action: 'insertAssyproc',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            wpHash: this.$route.query.wpHash,
            subassyID: this.subassyID,
            ceID: this.$parent.$auth.user.sub,
            partID: this.assyRoot,
            assyprocID: this.assyprocID,
            assyprocName: this.assyprocName,
            assySupplierName: this.assySupplierName,
            assySupplierLocation: this.assySupplierLocation,
            assyprocProcTime: this.assyprocProcTime,
            assyprocProcTimeUnit: this.assyprocProcTimeUnit,
            assyprocCavityQty: this.assyprocCavityQty,
            assyprocRejectRate: this.assyprocRejectRate,
            assyprocRejectUnit: this.assyprocRejectUnit,
            assyprocSetupTime: this.assyprocSetupTime,
            assyprocSetupUnit: this.assyprocSetupUnit,
            assyprocUnskilledQty: this.assyprocUnskilledQty,
            assyprocSkilledQty: this.assyprocSkilledQty,
            assyprocTecQty: this.assyprocTecQty,
            assyprocRemark: this.assyprocRemark,

          }).then( (response)=>{
            parent.myModel = false;
            this.fetchAllDataAssyproc();
            // this.procName = '';

            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if (this.actionButton == 'Update') {
          axios.post('/src/php/costimizer_collab_manage_assyprocess.php', {
            action: 'updateAssyproc',
            assyprocID: this.assyprocID,
            assyprocName: this.assyprocName,
            assySupplierName: this.assySupplierName,
            assySupplierLocation: this.assySupplierLocation,
            assyprocProcTime: this.assyprocProcTime,
            assyprocProcTimeUnit: this.assyprocProcTimeUnit,
            assyprocCavityQty: this.assyprocCavityQty,
            assyprocRejectRate: this.assyprocRejectRate,
            assyprocRejectUnit: this.assyprocRejectUnit,
            assyprocSetupTime: this.assyprocSetupTime,
            assyprocSetupUnit: this.assyprocSetupUnit,
            assyprocUnskilledQty: this.assyprocUnskilledQty,
            assyprocSkilledQty: this.assyprocSkilledQty,
            assyprocTecQty: this.assyprocTecQty,
            assyprocRemark: this.assyprocRemark,

          }).then((response)=>{
            this.myModel = false;
            this.fetchAllDataAssyproc();
            // this.ProcName = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Please fill all fields");
      }
    },
    fetchDataAssyproc: function (assyprocID) {
      let self = this
      axios.post('/src/php/costimizer_collab_manage_assyprocess.php', {
        action: 'fetchSingleAssyproc',
        assyprocID: assyprocID
      }).then(function (response) {
        self.assyprocID = response.data.assyprocID;
        self.assyprocName = response.data.assyprocName;
        self.assySupplierName = response.data.assySupplierName;
        self.assySupplierLocation = response.data.assySupplierLocation;
        self.assyprocProcTime = response.data.assyprocProcTime;
        self.procProcTimeUnit = response.data.procProcTimeUnit;
        self.assyprocCavityQty = response.data.assyprocCavityQty;
        self.assyprocRejectRate = response.data.assyprocRejectRate;
        self.assyprocRejectUnit = response.data.assyprocRejectUnit;
        self.assyprocSetupTime = response.data.assyprocSetupTime;
        self.assyprocSetupUnit = response.data.assyprocSetupUnit;
        self.assyprocUnskilledQty = response.data.assyprocUnskilledQty;
        self.assyprocSkilledQty = response.data.assyprocSkilledQty;
        self.assyprocTecQty = response.data.assyprocTecQty;
        self.assyprocRemark = response.data.assyprocRemark;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
      });
    },
    deleteDataAssyproc: function (assyprocID) {
      if (confirm("Are you sure you want to remove this data?")) {
        axios.post('/src/php/costimizer_collab_manage_assyprocess.php', {
          action: 'deleteAssyproc',
          assyprocID: assyprocID
        }).then( (response) => {
          this.fetchAllDataAssyproc();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },

    clipAssyProcess: function (assyprocessID, assyprocessName) {
      this.$store.state.clipboard.assyprocessID = assyprocessID;
      this.$store.state.clipboard.assyprocessName = assyprocessName;
      // Add clipPart to clip DB
      axios.post('/src/php/costimizer_collab_manage_clipboard.php', {
        action: 'clipAssyProcess',
        ceID: this.$parent.$auth.user.sub,
        userRole: this.userRole,
        clippedAssyProcessID: assyprocessID,
        clippedAssyProcessName: assyprocessName
      }).then((response) => {
        if ( response.data.message ) {
         // alert(response.data.message);
          response.data.message = '';
        }
      });
    },

    pasteAssyProcess: function (assyprocID) {


      axios.post('/src/php/costimizer_collab_manage_assyprocess.php', {
        action: 'pasteAssyProcess',

        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        assyprocID: assyprocID,
        wpHash: this.$route.query.wpHash,
        partID: this.partID,
        subassyID: this.subassyID,



      }).then((response) => {
        parent.myModel = false;
        this.fetchAllDataAssyproc();
        // this.commodityName = '';
        // Alert to replace by Toast
        if ( response.data.message ) {
          // alert(response.data.message);
          response.data.message = '';
        }
      });
    },


   getAssyProcessCost: function() {

      let assyProcCostCommodity = 0
      let assyProcCostEquipment = 0
      let assyProcCostBase = 0
      this.trigger ++
      this.assyProcCostSum = 0
      for (let i = 0; i < this.allDataAssyproc.length; i++) {
        this.allDataAssyproc[i].assyProcessCost = 0;
        for (let s = 0; s < this.$store.state.commodity.length; s++) {
          if (this.allDataAssyproc[i].assyprocID == this.$store.state.commodity[s].procID) {
            assyProcCostCommodity = Math.round((assyProcCostCommodity + this.$store.state.commodity[s].commodityCost) * 100) / 100;
            this.allDataAssyproc[i].assyProcessCost = Math.round((this.allDataAssyproc[i].assyProcessCost + this.$store.state.commodity[s].commodityCost) * 100) / 100;

          }
        }
        for (let p = 0; p < this.$store.state.equipment.length; p++) {
          if (this.allDataAssyproc[i].assyprocID == this.$store.state.equipment[p].procID) {
            assyProcCostEquipment = Math.round((assyProcCostEquipment + this.$store.state.equipment[p].equipmentCost) * 100) / 100
            this.allDataAssyproc[i].assyProcessCost = Math.round((this.allDataAssyproc[i].assyProcessCost + this.$store.state.equipment[p].equipmentCost) * 100) / 100

          }
        }
        assyProcCostBase = assyProcCostBase + Math.round((this.allDataAssyproc[i].assyProcBaseCost) * 100) / 100;
        this.allDataAssyproc[i].assyProcessCost = Math.round((this.allDataAssyproc[i].assyProcessCost + this.allDataAssyproc[i].assyProcBaseCost) * 100) / 100;

    }
   }
  },


  created:function(){
    this.compUID = uuidv4();
    this.compUID = this.compUID.replace(/-/g,'');
    this.compUID = 'a'+this.compUID;
    this.compUID = this.compUID.substring(0,10);
    this.hcompUID = '#'+this.compUID;
    this.mhcompUID = 'mh'+this.compUID;
    this.mchcompUID = '#mc'+this.compUID;
    this.mccompUID = 'mc'+this.compUID;

    // console.log("This component's unique id is: " + this.compUID);
    this.fetchAllDataAssyproc();
    this.$forceUpdate();


      this.getAssyProcessCost();
      this.timer = setInterval(this.getAssyProcessCost, 5000000);



   // Listen to EVENTS from Children  FOR TEST
  //  this.$on('commodityToAssembly',costSum => {

  //  });
  },

  computed: {
    sumAssyProcCost() {
         let sumAssyProcCostVal = 0

      for (let y=0 ; y < this.allDataAssyproc.length; y++) {
        for (let z = 0; z < this.$store.state.commodity.length; z++) {
          if (this.$store.state.commodity[z].procID == this.allDataAssyproc[y].assyprocID) {

            sumAssyProcCostVal = sumAssyProcCostVal + this.$store.state.commodity[z].commodityCost;
          }
        }
        for (let z = 0; z < this.$store.state.equipment.length; z++) {
          if (this.$store.state.equipment[z].procID == this.allDataAssyproc[y].assyprocID) {
            sumAssyProcCostVal = sumAssyProcCostVal + this.$store.state.equipment[z].equipmentCost
          }
        }
        sumAssyProcCostVal   =  sumAssyProcCostVal + this.allDataAssyproc[y].assyProcBaseCost

   //   sumAssyProcCostVal =  sumAssyProcCostVal + this.trigger
   //   sumAssyProcCostVal =  sumAssyProcCostVal - this.trigger
      sumAssyProcCostVal =  Math.round(sumAssyProcCostVal*100)/100

      // move result for all processes below subassembly or main assembly to store
      if (sumAssyProcCostVal != 0 ) {
        this.$store.commit('addAssyProcSum', {
          wpID: this.wpID,
          procID: this.procID,
          subassyID: this.allDataAssyproc[y].subassyID,
          assyProcCostSum: sumAssyProcCostVal
        });
      }
      }
      return (sumAssyProcCostVal)
    }
  }



}
</script>

<style scoped>

.accordion {
  padding-left: 10px;
}

.accordion-header {
  background-color: #004D6C;
  color: #E7E9DF;
  padding-left: 10px;
}
.accordion-button  {
  background-color: #004D6C;
  color: #E7E9DF;
  padding-top:5px;
  padding-bottom:5px;
}


table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

.modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height:100px;
  overflow-y: auto;
  position: relative;
}


</style>