<template>
<div>


  <div class="container" id="Workpackage_Header" style="margin-bottom: 100px">
    <h2>SYSTEM MANAGER   (BETA!)</h2>
    <br />

    <!--  PROJECT SECTION -->
    <h3 align="center">Please select a project</h3>

    <br />
    <div id="selector1">
      <select v-model="projectNo">
        <option v-for="(row) in allDataProjects"  v-bind:key="row" :value="row.projectNo">
          {{row.projectNo}}: {{row.projectName}}
        </option>
      </select>
      <br>
      <br>
      <span style="color:blue; font-weight: bold">Selected: {{ projectNo }}</span>
    </div>
    <br/>
    <div v-if="projectNo !=''">
      <div class="col-md-12" align="left">
        <input v-if="userRole==='admin' || userRole==='projectmanager'  " type="button" class="btn btn-info btn-xs" value="Load Systems" @click="fetchAllDataSystems(projectNo)"  style="margin-right: 10px"/>
      </div>
      </div>
    </div>


        <div class="container" id="Workpackage_Header">
          <h2>SYSTEM OVERVIEW</h2>
          <br />
          <h3 align="center">Hallo {{ $auth.user.name }}, currently available Systems</h3>
          <h3  align="center">Your Role: "<i style="color:red">{{userRole}}"</i></h3>
          <br />
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6" align="right">
                </div>
              </div>
            </div>
            <div class="panel-body">
              <div>
                <table :key="projectNo" class="table table-hover table-sm table-bordered">
                  <tr >
                    <th col-sm-1>SystemID</th>
                    <th col-sm-1>Pic</th>
                    <th col-sm-2>SystemName</th>
                    <th col-sm-2>SystemRef</th>
                    <th col-sm-1>WPs included</th>
                    <th col-sm-1>Status</th>
                    <th col-sm-1>Owner</th>
                    <th col-sm-3>Description</th>
                    <th col-sm-1>Open</th>
                    <th col-sm-1>Actions</th>
                  </tr>
                  <tr   v-for="row in allDataSystems" v-bind:key="row">
                    <td col-sm-1>{{ row.systemID }}</td>
                    <td col-sm-1><img :src='row.systemImgPath1' width="100px" height="auto" alt="partpic"   style="padding-right:5px"  @click="showImageModal(row.systemImgPath1, row.systemName)"/></td>
                    <td col-sm-2>{{ row.systemName }}</td>
                    <td col-sm-2>{{ row.systemRef }}</td>
                    <td col-sm-1>{{ row.systemWPList}}</td>
                    <td col-sm-1>{{ row.systemStatus }}</td>
                    <td v-if="userRole != 'client'"  col-sm-1>{{ row.systemOwner }}</td>
                    <td v-if="userRole == 'client'"  col-sm-1>Team</td>
                    <td col-sm-3>{{ row.systemDesc }}</td>
                    <td col-sm-1 ><button class="btn btn-primary" style="color:black">Open</button></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>


</div>
</template>


<script>

const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

export  default {
  name: "System_Manager",
  components: {},
  props: {
    namespace: Array,

  },

  data: function () {
    return {
      allDataProjects:{},

      allDataSystems:{},
      systemID:'', systemName:'', systemHash:'', systemRef:'', systemWPList:'',  systemImgPath1:'', systemImgPath2:'', systemOwner:'',
      projectNo:'', userRole:'', ceID:'', systemDesc:'', systemStatus:'', systemReleased:'', wpImg2:'', wpID2:'', wpRef2:'',
      wpOwner:'', wpStart:'', wpEnd:"", wpRef:'', allDataWPHeader:'',
    };
  },
  methods: {


    // GET Projects
    fetchAllDataProjects: function () {
      axios.post('/src/php/costimizer_collab_manage_project_entry.php', {
        action: 'fetchallProjects',
        ceID: this.$parent.$auth.user.sub,
        userRole: this.namespace['https://www.costimizer.de/role']
      }).then((response) => {
        this.allDataProjects = response.data;
        console.log(this.allDataProjects);
        console.log('project loaded');
      });
    },

    // MANAGE Systems
    fetchAllDataSystems:function(projectNo) {
      axios.post('/src/php/costimizer_collab_manage_systems.php', {
        action:'fetchallSystems' , projectNo: projectNo, ceID : this.$parent.$auth.user.sub, userRole : this.namespace['https://www.costimizer.de/role']
      }).then((response)=>{
        this.allDataSystems=response.data;
        console.log(this.allDataSystems);


      });
    },

  },

  mounted:function() {

          this.userRole = this.namespace['https://www.costimizer.de/role'];
          this.fetchAllDataProjects();
         // this.fetchAllDataSystems('CA250051');

  },


}
</script>


<style scoped>

table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}
tr {
  font-size: small !important;
}



</style>