<template>
  <div class="container" style="padding-top: 100px">
    <Project_HeaderLab />

  </div>

</template>

<script>

import Project_HeaderLab from "@/components/Project_HeaderLab";
export default {
  name: "ProjectLab, Project_HeaderLab",
  props: {
    wpName: String
  },
  components: {Project_HeaderLab}
}
</script>

<style scoped>

</style>