<template>
  <div class="container" style="padding-top: 100px">
    <Project_Header />

  </div>

</template>

<script>

import Project_Header from "@/components/Project_Header";
export default {
  name: "Project, Project_Header",
  props: {
    wpName: String
  },
  components: {Project_Header}
}
</script>

<style scoped>

</style>