<template>
  <div>


    <div class="accordion accordion-flush" :id="compUID">
      <div class="accordion-item">
        <div class="row justify-content-start">
          <div class="col-11">
            <h2 class="accordion-header" :id="mhcompUID">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID"  style="background-color: #004D6C; color: #E7E9DF">
                Manufacturing Processes  Current Cost: <strong>{{sumProcCost}}€</strong> (incl. Overhead)
              </button>
            </h2>
          </div>
          <div class="col-1 my-auto" style="display:flex; justify-content: space-between; " >
            <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="openModelProc(partID)" value="Add">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </button>
            <button v-if="$store.state.clipboard.processID != '' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="pasteProcess($store.state.clipboard.processID)" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Paste Equipment">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
            </button>
          </div>
        </div>




        <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID" >

            <div class="accordion accordion-flush" v-for="row in allDataProc" v-bind:key="row" :id="row.procID"  >
            <div class="accordion-item">
              <div class="row justify-content-start">
                <div class="col-10">
                  <h2 class="accordion-header" :id="'flush'+row.procID">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+row.procID" aria-expanded="false" :aria-controls="'flush-collapse'+row.procID" style="background-color: #0076A3; color: #E7E9DF">
                      Process: {{row.procName}} [Cycle Time: {{row.procProcTime}}{{row.procProcTimeUnit}}, Cavities: {{row.procCavityQty}}, BatchSize: {{row.procBatch}}, <strong>ProcessCost: {{row.processCost}} €</strong> ]
                    </button>
                  </h2>
                </div>
                <div class="col-2 my-auto" >
                  <button  v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')"  type="button" class="btn btn-link btn-sm" @click="fetchDataProc(row.procID)" value="Edit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>

                  </button>

                  <button v-if="$store.state.clipboard.processID != row.procID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"  @click="clipProcess(row.procID, row.procName)" value="Copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Process">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                    </svg>
                  </button>

                  <button v-if="$store.state.clipboard.processID == row.procID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"  value="Clipped" data-bs-toggle="tooltip" data-bs-placement="top" title="Process in clipboard for paste">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="coral" class="bi bi-app-indicator" viewBox="0 0 16 16">
                      <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                      <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    </svg>
                  </button>


                  <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="deleteDataProc(row.procID)" value="Delete">
                    <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>
                </div>
              </div>
              <div :id="'flush-collapse'+row.procID" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+row.procID" :data-bs-parent="'#'+row.procID">

                <Equipment v-bind:procID="row.procID"  v-bind:supplierLocation="row.SupplierLocation"
                           v-bind:procProcTime="row.procProcTime"
                           v-bind:procProcTimeUnit="row.procProcTimeUnit"
                           v-bind:procCavityQty="row.procCavityQty"
                           v-bind:procRejectRate="row.procRejectRate"
                           v-bind:procRejectUnit="row.procRejectUnit"
                           v-bind:procSetupTime="row.procSetupTime"
                           v-bind:procSetupUnit="row.procSetupUnit"
                           v-bind:wpStatus="wpStatus"
                           v-bind:namespace="namespace"
                />



                <Commodities v-bind:procID="row.procID" v-bind:wpStatus="wpStatus" v-bind:namespace="namespace"/>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>



    <div v-if="myModel">
      <transition name="model">
        <div class="modal-mask" v-bind:style="{top: mouseY}" >
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                  <h4 class="modal-title">{{ dynamicTitle }}</h4>
                </div>
                <div class="modal-body">
                  <form class="row g-3 " >
                    <div class="form-group" >
                      <label>Enter Process Name</label>
                      <input type="text" class="form-control"   v-model="procName" required />
                    </div>


                    <div class="input-group mb-3" >
                      <span class="input-group-text">Process Time</span>
                      <input type="number" class="form-control"   step="0.1"   v-model="procProcTime" required />
                      <span class="input-group-text">
                        <select class="form-select" aria-label="Process Time Unite" v-model="procProcTimeUnit" :value="procProcTimeUnit" required  >
                          <option  value="s">s</option>
                          <option value="min">min</option>
                          <option value="h">h</option>
                        </select>
<!--                      <input type="text" class="form-control"  size="3"  :value="procProcTimeUnit"  v-model="procProcTimeUnit" readonly />-->
                      </span>
                    </div>

                    <div class="input-group mb-3" >
                      <span class="input-group-text">No. of Cavities </span>
                      <input type="number" class="form-control"   min="0" max="500" v-model="procCavityQty" required />
                      <span class="input-group-text">Parts</span>
                    </div>

                    <div class="input-group mb-3" >
                      <span class="input-group-text">Batch Size (optional)</span>
                      <input type="number" class="form-control"   min="0" max="500000" v-model="procBatch"  />
                      <span class="input-group-text">Parts</span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Reject Rate </span>
                      <input type="number" class="form-control"  min="0" max="100"  step="0.01" v-model="procRejectRate"  required/>
                      <span class="input-group-text" >
                      <input type="hidden" class="form-control"  size="3"    v-model="procRejectUnit" readonly />
                         <span class="input-group-text">%</span>
                      </span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Setup Time </span>
                      <input type="number" class="form-control"   min="0" max="500"  step="1 " v-model="procSetupTime" required />
                      <span class="input-group-text" >
                      <input type="hidden" class="form-control"  size="3"    v-model="procSetupUnit" readonly />
                         <span class="input-group-text">min</span>
                      </span>
                    </div>

                    <div class="input-group mb-3" >
                      <span class="input-group-text">Staff allocation Unskilled Worker</span>
                      <input type="number" class="form-control"  min="0" max="10"  step="0.1" v-model="procUnskilledQty" required />
                      <span class="input-group-text">Persons</span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Staff allocation Skilled Worker</span>
                      <input type="number" class="form-control"   min="0" max="10" step="0.1" v-model="procSkilledQty" required />
                      <span class="input-group-text">Persons</span>
                    </div>
                    <div class="input-group mb-3" >
                      <span class="input-group-text">Staff allocation Technicians </span>
                      <input type="number" class="form-control" min="0" max="10"   step="0.1" v-model="procTecQty" required />
                      <span class="input-group-text">Persons</span>
                    </div>

                    <div class="form-group" style="margin-top: 20px">
                      <label>Remark to the Process</label>
                      <input type="text" class="form-control" v-model="procRemark" />
                    </div>
                    <br />
                    <div align="center">
                      <input type="hidden"   v-model="partID"  :placeholder="partID">
                      <input type="hidden"   v-model="procID">
                      <input type="hidden" v-model="hiddenId" />
                      <input v-if="userRole!= 'qamanager'" type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataProc" />
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>


const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

import { v4 as uuidv4 } from 'uuid';
import Equipment from "@/components/Equipment";
import Commodities from "@/components/Commodities";

export  default {
  name: "Processes",
  components: {Equipment, Commodities},

  props: {
    partID: String,
    wpStatus: String,
    namespace: Array,
  },

  data: function () {
    return {
      allDataProc:{}, myModel: false, actionButton: 'Insert', dynamicTitle: 'Add Data', WP_Name: '',
      projectNo:this.$route.query.projectno, wpID: this.$route.query.wpID, compUID: '', hcompUID :'' , mcompUID:'', mhcompUID:'', mchcompUID:'', mccompUID:'',
      procName: '', procProcTime: '', procProcTimeUnit: 's', procCavityQty: 1, procRejectRate:'', procRejectUnit:'%', procSetupTime: '', procSetupUnit: 'min', procUnskilledQty: '',
      procSkilledQty: '', procTecQty: '', procRemark: '', procID:'', procBatch:'', processCost: 0, procBaseCost: 0, trigger: 0, userRole : this.namespace['https://www.costimizer.de/role'],
      mouseY: 0
    };
  },



  methods: {

    // Get data from VUEX  (OCMM COST AND EQUPMENT COST


    //  MANAGE PROCESSES
    fetchAllDataProc: function () {
      axios.post('/src/php/costimizer_collab_manage_process.php', {
        action: 'fetchallProc', projectNo: this.$route.query.projectno, wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        partID: this.partID,
        userRole : this.$route.query.userRole
      }).then((response) => {
        this.allDataProc = response.data;
      });
    },

    openModelProc: function (partID) {
      this.procName = '';
      this.procProcTime = '';
      this.procProcTimeUnit = 's';
      this.procCavityQty = '',
      this.procBatch = '',
      this.procRejectRate = '',
      this.procRejectUnit = '',
      this.procSetupTime = '',
      this.procSetupUnit = 'min',
      this.procUnskilledQty = '',
      this.procSkilledQty = '',
      this.procTecQty = '',
      this.procRemark = '',
      this.partID = partID;
      this.actionButton = "Save";
      this.dynamicTitle = "Add Process";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },
    submitDataProc: function () {
      if (this.procName != '') {
        if (this.actionButton == 'Save') {
          this.myModel = false;
          axios.post('/src/php/costimizer_collab_manage_process.php', {
            action: 'insertProc',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            wpHash: this.$route.query.wpHash,
            ceID: this.$parent.$auth.user.sub,
            partID: this.partID,
            procID: this.procID,
            procName: this.procName,
            procProcTime: this.procProcTime,
            procProcTimeUnit: this.procProcTimeUnit,
            procCavityQty: this.procCavityQty,
            procBatch: this.procBatch,
            procRejectRate: this.procRejectRate,
            procRejectUnit: this.procRejectUnit,
            procSetupTime: this.procSetupTime,
            procSetupUnit: this.procSetupUnit,
            procUnskilledQty: this.procUnskilledQty,
            procSkilledQty: this.procSkilledQty,
            procTecQty: this.procTecQty,
            procRemark: this.procRemark,

          }).then( (response)=>{
            parent.myModel = false;
            this.fetchAllDataProc();
            // this.procName = '';

            if ( response.data.message ) {
               alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if (this.actionButton == 'Update') {
          axios.post('/src/php/costimizer_collab_manage_process.php', {
            action: 'updateProc',
            procID: this.procID,
            procName: this.procName,
            procProcTime: this.procProcTime,
            procProcTimeUnit: this.procProcTimeUnit,
            procCavityQty: this.procCavityQty,
            procBatch: this.procBatch,
            procRejectRate: this.procRejectRate,
            procRejectUnit: this.procRejectUnit,
            procSetupTime: this.procSetupTime,
            procSetupUnit: this.procSetupUnit,
            procUnskilledQty: this.procUnskilledQty,
            procSkilledQty: this.procSkilledQty,
            procTecQty: this.procTecQty,
            procRemark: this.procRemark,

          }).then((response)=>{
            this.myModel = false;
            this.fetchAllDataProc();
            // this.ProcName = '';

            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Fill in all Fields");
      }
    },
    fetchDataProc: function (procID) {
      let self = this
      axios.post('/src/php/costimizer_collab_manage_process.php', {
        action: 'fetchSingleProc',
        procID: procID
      }).then(function (response) {
        self.procID = response.data.procID;
        self.procName = response.data.procName;
        self.procProcTime = response.data.procProcTime;
        self.procProcTimeUnit = response.data.procProcTimeUnit;
        self.procCavityQty = response.data.procCavityQty;
        self.procBatch = response.data.procBatch;
        self.procRejectRate = response.data.procRejectRate;
        self.procRejectUnit = response.data.procRejectUnit;
        self.procSetupTime = response.data.procSetupTime;
        self.procSetupUnit = response.data.procSetupUnit;
        self.procUnskilledQty = response.data.procUnskilledQty;
        self.procSkilledQty = response.data.procSkilledQty;
        self.procTecQty = response.data.procTecQty;
        self.procRemark = response.data.procRemark;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
      });
    },
    deleteDataProc: function (procID) {
      if (confirm("Are you sure you want to remove this data?")) {
        axios.post('/src/php/costimizer_collab_manage_process.php', {
          action: 'deleteProc',
          procID: procID
        }).then( (response) => {
          this.fetchAllDataProc();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },

    /// PASTE FUNCTIONS
    clipProcess: function (processID, processName) {
      this.$store.state.clipboard.processID = processID;
      this.$store.state.clipboard.processName = processName;
      // Add clipPart to clip DB
      axios.post('/src/php/costimizer_collab_manage_clipboard.php', {
        action: 'clipProcess',
        ceID: this.$parent.$auth.user.sub,
        userRole: this.userRole,
        clippedProcessID: processID,
        clippedProcessName: processName
      }).then((response) => {
        if ( response.data.message ) {
         // alert(response.data.message);
          response.data.message = '';
        }
      });

    },


    pasteProcess: function (procID) {
      axios.post('/src/php/costimizer_collab_manage_process.php', {
        action: 'pasteProcess',

        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        procID: procID,
        wpHash: this.$route.query.wpHash,
        partID: this.partID



      }).then((response) => {
        parent.myModel = false;
        this.fetchAllDataProc();
        // this.commodityName = '';
        // Alert to replace by Toast
        if ( response.data.message ) {
          // alert(response.data.message);
          response.data.message = '';
        }
      });
    },

    getProcessCost ()  {
      this.trigger ++
      for (let i=0 ; i < this.allDataProc.length; i++)
        {
        this.allDataProc[i].processCost = 0;
        for (let s=0 ; s < this.$store.state.commodity.length; s++)
        {
          if (this.allDataProc[i].procID == this.$store.state.commodity[s].procID)
          {
            this.allDataProc[i].processCost  = Math.round((this.allDataProc[i].processCost + this.$store.state.commodity[s].commodityCost)*100)/100

          }
        }
        for (let s=0 ; s < this.$store.state.equipment.length; s++)
        {
          if (this.allDataProc[i].procID == this.$store.state.equipment[s].procID)
          {
            this.allDataProc[i].processCost  = Math.round((this.allDataProc[i].processCost + this.$store.state.equipment[s].equipmentCost)*100)/100
          }
        }
        this.allDataProc[i].processCost = Math.round((this.allDataProc[i].processCost + this.allDataProc[i].procBaseCost)*100)/100;

      }

    },


  },


  created:function(){
    this.compUID = uuidv4();
    this.compUID = this.compUID.replace(/-/g,'');
    this.compUID = 'a'+this.compUID;
    this.compUID = this.compUID.substring(0,10);
    this.hcompUID = '#'+this.compUID;
    this.mhcompUID = 'mh'+this.compUID;
    this.mchcompUID = '#mc'+this.compUID;
    this.mccompUID = 'mc'+this.compUID;

  //  console.log("This component's unique id is: " + this.compUID);
    this.fetchAllDataProc();
    this.getProcessCost();
    this.timer = setInterval(this.getProcessCost, 5000000);

  },


  computed: {
    sumProcCost() {
      let sumProcCostVal = 0
  
     for (let y=0 ; y < this.allDataProc.length; y++) {
        for (let z = 0; z < this.$store.state.commodity.length; z++) {
          if (this.$store.state.commodity[z].procID == this.allDataProc[y].procID) {

            sumProcCostVal = sumProcCostVal + this.$store.state.commodity[z].commodityCost;
          }
        }
        for (let z = 0; z < this.$store.state.equipment.length; z++) {
          if (this.$store.state.equipment[z].procID == this.allDataProc[y].procID) {
            sumProcCostVal = sumProcCostVal + this.$store.state.equipment[z].equipmentCost;
          }
        }
        sumProcCostVal   =  sumProcCostVal + this.allDataProc[y].procBaseCost
      }
     sumProcCostVal =  sumProcCostVal + this.trigger
      sumProcCostVal =  sumProcCostVal - this.trigger

      sumProcCostVal =  Math.round(sumProcCostVal*100)/100

      // move result for all processes below subassembly or main assembly to store

      if (sumProcCostVal != 0 ) {
        this.$store.commit('addProcSum', {
          wpID: this.wpID,
          procID: this.procID,
          partID: this.partID,
          procCostSum: sumProcCostVal
        });
      }
      return (sumProcCostVal)


    }
  }
  

}
</script>

<style scoped>

.accordion {
  padding-left: 10px;
}

.accordion-header {
  background-color: #004D6C;
  color: #E7E9DF;
  padding-left: 10px;
}
.accordion-button  {
  background-color: #004D6C;
  color: #E7E9DF;
  padding-top:5px;
  padding-bottom:5px;
}


table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

.modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  font-size: small;
}


</style>