<template>
<div style="padding-top: 100px">
  <div class="container">
  <h4>Project No.:  {{this.$route.query.projectNo}}</h4>
  <Workpackage_Header   v-bind:namespace="namespace" />
  </div>
</div>
</template>

<script>


import Workpackage_Header from "@/components/Workpackage_Header";
export default {
  name: "Workpackages, Workpackage_Header",
  components: {Workpackage_Header },
  mounted: function () {

    this.projectNo = this.$route.query.projectNo
  },

  data() {
    return{
      projectNo: null,    namespace : (this.$parent.$auth.user)
    };
  },
  created: function () {
    this.$store.commit('delWpSum', {
      projectNo: this.$route.query.projectNo
    });
  }
}
</script>

<style scoped>

</style>