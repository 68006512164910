<template>
<div >


    <div class="accordion accordion-flush" :id="compUID">
    <div class="accordion-item">

        <div class="row justify-content-start">
          <div class="col-11">
            <h2 class="accordion-header" :id="mhcompUID">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  style="margin-right:10px" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                  </svg>
                  PARTS  [Current Sum of Part Costs:  <strong>{{sumPartCost}}</strong>€]</button>
            </h2>
          </div>
          <div class="col-1 my-auto" style="display:flex; justify-content: space-between; ">
            <div v-if="userRole != 'costengineer' ">
            <button  v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="openModelPart" value="Add">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </button><button v-if="$store.state.clipboard.partID != '' && userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="pastePart($store.state.clipboard.partID, '','normal')" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Paste Part">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                </svg>
              </button>

            </div>
          </div>
        </div>

      <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID">

        <div class="accordion accordion-flush" v-for="row in allDataPart" v-bind:key="row" :id="row.partID" >
          <div class="accordion-item">
            <div class="row justify-content-start">
              <div class="col-10">
              <h2 class="accordion-header" :id="'flush'+row.partID"  >
                <button  class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+row.partID" aria-expanded="false" :aria-controls="'flush-collapse'+row.PartID" style="background-color: #F39663; color: darkgreen">
                  <svg  @mouseover="showImageModal(row.imgName, row.partName)"   xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                    <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
                    <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
                  </svg>
                  <img :src='row.imgName' width="5%" alt="noPic"   style="padding-right:10px"   />
                  {{row.partName}}, Ref: {{row.partRef}}[ Qty:  {{row.partQty}} Location: {{row.supplierLocation}}]<strong>[Cost:  {{Math.round(row.partCost*row.partQty*100)/100}} € </strong>]  (Estimated Cost: {{Math.round(row.partEstCost*row.partQty*100)/100}}€)
                </button>


              </h2>
              </div>
              <div class="col-2 my-auto" >


                <button  v-if="row.partRef === ' 2013-2659'"  type="button" class="btn btn-link btn-sm" @click="openPartInfo">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgrey" class="bi bi-info-square" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                  </svg>
                </button>


                <button  v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')"  type="button" class="btn btn-link btn-sm" @click="fetchDataPart(row.partID)"  value="Edit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                </button>

                <button v-if="$store.state.clipboard.partID != row.partID && userRole!= 'client' " type="button" class="btn btn-link btn-sm"  @click="clipPart(row.partID, row.partName)" value="Copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Part to Clipboard">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>

                <button v-if="$store.state.clipboard.partID != row.partID && $store.state.clipboard.partID && userRole!= 'client' " type="button" class="btn btn-link btn-sm"  @click="pastePart($store.state.clipboard.partID, row.partID, 'special')" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Past Part Content Only">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="magenta" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>

                <button v-if="$store.state.clipboard.partID == row.partID  && userRole!= 'client'" type="button" class="btn btn-link btn-sm"  value="Clipped" data-bs-toggle="tooltip" data-bs-placement="top" title="Part in clipboard for paste">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="coral" class="bi bi-app-indicator" viewBox="0 0 16 16">
                    <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                    <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  </svg>
                </button>


                <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="deleteDataPart(row.partID)" value="Delete Part">
                  <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                  </svg>
                </button>
              </div>
            </div>
                <div :id="'flush-collapse'+row.partID" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+row.partID" :data-bs-parent="'#'+row.partID">
                  <Material v-bind:partID="row.partID" v-bind:wpStatus="wpStatus"  v-bind:namespace="namespace" />
                  <Processes v-bind:partID="row.partID"  v-bind:wpStatus="wpStatus" v-bind:namespace="namespace"/>
                </div>
          </div>
        </div>
      </div>
    </div>
    </div>



 <div v-if="myModel" >
    <transition name="model">
      <div class="modal-mask" v-bind:style="{top: mouseY}" >
       <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">

              <div>
                <div v-if="!successAlert && previewImage"
                    class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${previewImage})` }"
                    @click="selectImage"  >
                </div>

                <div v-if="successAlert && previewImage"
                     class="imagePreviewWrapper"
                     :style="{ 'background-image': `url(${previewImage})` }"
                     >
                </div>


                <input v-if="!successAlert"
                       ref="file"
                    type="file"
                    id = "file"
                    v-on:change="handleFileUpload()"
                    @input="pickFile"  />
              </div>
              <button v-if="!successAlert" v-on:click="submitFile()">SAVE PICTURE</button>


                <div class="form-group">
                  <label>Enter Part Name</label>
                  <input type="text" class="form-control" v-model="partName" required/>
                </div>
                <div class="form-group">
                  <label>Part Ref-ID</label>
                  <input type="text" class="form-control" v-model="partRef" />
                </div>
                <div class="form-group">
                <label>Enter Quantity of Parts</label>
                <input type="number" min="1" max="999" step="1" class="form-control" v-model="partQty" required/>
              </div>
                <div class="form-group">
                  <label>Enter Supplier Location, current Location:</label>
                  <input type="text" class="form-control" v-model="supplierLocation" readonly><br>
                  <autocomplete  id="supplierLocation"  ref="autocomplete" :search="searchLocation"  placeholder="Search Location"   default-value="Mexico" @submit="handleSubmit"/>

                </div>
                <div class="form-group">
                  <label>Enter Supplier Name</label>
                  <input type="text" class="form-control" v-model="supplierName" placeholder="optional"/>
                </div>
                <div class="form-group">
                  <label style="color:blue; font-weight: bold">Part Description  / Remark (e.g. to Estimation)</label>

                  <textarea  class="form-control input-sm" v-model="partDesc" placeholder="recommended"/>
                </div>
                <div v-if="partSource == 'Internal'"  class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Sourcing Type</span>
                  <select class="form-select" aria-label="Select Sourcing Type" v-model="partSource" required style="color:darkred">
                    <option  value="Internal" selected>Internal</option>
                    <option value="External" >External</option>
                  </select>
                  </div>
                <div v-if="partSource == 'External' || partSource == '' "  class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Sourcing Type</span>
                  <select class="form-select" aria-label="Select Sourcing Type" v-model="partSource" required style="color:darkred">
                    <option  value="External" selected>External</option>
                    <option value="Internal" >Internal</option>
                  </select>
                </div>
                <div class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text" style="font-weight: bold">PART COST ESTIMATION</span>

                </div>
                <div class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Part Weight</span>
                  <input type="number" class="form-control"   step="0.1" v-model="partWeight" required />
                  <span class="input-group-text">g</span>
                </div>

                <div class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Main Material</span>
                  <select   class="form-select" aria-label="Material Price Unit" v-model="partMainMat" required style="color:blue; width: 5%">
                    <option  :value="partMainMat" selected>{{ partMainMat }}</option>
                    <option value="Metal" >Metal</option>
                    <option value="Polymer" >Polymer</option>
                    <option value="Metal and Polymer" >Metal and Polymers</option>
                    <option value="Electronic" >Electronic</option>
                    <option value="Fabrics" >Fabrics</option>
                    <option value="Composites" >Composites</option>
                  </select>
                </div>

                <div class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Complexity</span>
                  <input type="range" class="form-range" min="0" max="100" id="complexityRange"  v-model="partComplexity">
                </div>

              <div class="input-group mb-3" style="margin-top: 20px">
                <span class="input-group-text">Estimated Cost</span>
                <input type="number" class="form-control"    v-model="partEstCost"  />
                <span class="input-group-text">€</span>
              </div>



                  <br />
                <div align="center">
                  <input type="hidden"   v-model="procID"  :placeholder="procID" >
                  <input type="hidden"   v-model="partID" :placeholder="partID">
                  <input type="hidden"   v-model="subassyID" :placeholder="subassyID">
                  <input type="hidden" v-model="hiddenId" />

                  <input v-if="userRole!= 'qamanager'" type="button"  name="submit" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataPart" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>
            </div>
          </div>
        </div>-->
  </div>
    </transition>
  </div>


  <div v-if="myModelPic"  @click="closeImageModal">
    <transition name="model">
      <div class="modal-mask" v-bind:style="{top: mouseY}" >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModelPic=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{dynamicTitlePic}}</h4>
              </div>

              <img :src='partPath' width="100%" alt="partpic"   style="padding:10px"  />


            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <!-- MODAL FOR PART INFO -->
  <div v-if="partInfoModal" >
    <transition name="model">
      <div class="modal-mask" v-bind:style="{top: mouseY}" >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="partInfoModal=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Part Manufacturing Classification</h4>
              </div>
              <div class="modal-body">



                <div class="form-group">
                  <label>Part Type (Main Classification)</label>
                  <input type="text" class="form-control" value="Injection Molding" readonly/>
                </div>
                <div class="form-group">
                  <label>Form Complexity</label>
                  <input type="text" class="form-control" value="Mid" readonly/>
                </div>
                <div class="form-group">
                  <label>Material Complexity</label>
                  <input type="text" class="form-control" value="Low" readonly/>
                </div>
                <div class="form-group">
                  <label>Cost Driver</label>
                  <input type="text" class="form-control" value="Size (Tooling)" readonly/>
                </div>
                <div class="form-group">
                  <label>Part Size/Weight Classification</label>
                  <input type="text" class="form-control" value="333 [large, light]" readonly/>
                </div>
                <div class="form-group">
                  <label>Necessary Closing Force </label>
                  <input type="text" class="form-control" value="8025 kN" readonly/>
                </div>
                <div class="form-group">
                  <label>Typical Tooling</label><br>
                  <img src="../assets/instrument_panel_tooling.jpg" height="150" width="250"/>
                </div>
                <div class="form-group">
                  <label style="color:blue; font-weight: bold">Process Description</label>
                  <p>The large parts are molding in automated injection molding cells which are typically
                  treated as one working cell or process step incl. follwing inline steps:</p>
                  <br>
                  <p>1. Injection molding in automated line with integrated Rrbot</p>
                  <p>2. Part removal from tool</p>
                  <p>3. Deburring inline (in same center)</p>
                  <p>4. Place part to EOL (in same center)</p>

                 </div>
                <div class="form-group">
                  <label>Typical Manufacturing Cell</label><br>
                  <img src="../assets/instrument_panel_Injection_molding.jpg" height="150" width="250"/>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="partInfoModal=false"><span aria-hidden="true">&times;</span></button>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </transition>
  </div>







</div>
  </template>

<script>

import {v4 as uuidv4} from "uuid";



const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

import Material from "@/components/Materials";
import Processes from "@/components/Process";

// import the component
import countries from '../assets/datalists/countries.json'
import Autocomplete from '@trevoreyre/autocomplete-vue'

// import the style
import '@trevoreyre/autocomplete-vue/dist/style.css'

// MODAL
// import VueFinalModal from 'vue-final-modal/lib'


export  default {
  name: "Parts",
  components: {Processes, Material, Autocomplete
},


  props: {
    subassyID: String,
    wpStatus: String,
    namespace: Array,
  },

  data: function () {
    return {
      allDataPart: {},
      myModel: false,
      myModelPic: false,
      actionButton: 'Insert',
      dynamicTitle: 'Add Data',
      dynamicTitlePic: '',
      partPath: '',
      partQty: '1',
      partName: {},
      projectNo: this.$route.query.projectno,
      wpHash: this.$route.query.wpHash,
      wpID: this.$route.query.wpID,
      partDesc: '',
      partRef: '',
      partSource: '',
      partTypeE: '',
      partWeight: 0,
      partMainMat: '',
      partComplexity: 1,
      supplierLocation: '',
      supplierName: '',
      previewImage: null,
      file: '',
      successAlert: false,
      errorAlert: false,
      uploadedImage: '',
      imgName: '',
      errorMessage: '',
      successMessage: '',
      partTotalCost: 0,
      trigger: 0,
      userRole: this.namespace['https://www.costimizer.de/role'],
      partInfoModal:false,
      mouseY: 0

    };
  },


  methods: {



    getPartCostTrigger() {
      this.trigger++;

    },


    // SELECT IMAGE
    selectImage () {
      this.$refs.file.click()
    },
    pickFile () {
      let input = this.$refs.file
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    //  Show Pic on Mouseover as Modal


    showImageModal: function(imgName, partName) {
      this.dynamicTitlePic = partName;
      this.partPath = imgName;
      this.myModelPic = true;
    },
    closeImageModal: function() {

      this.myModelPic = false;
    },


    //  Save IMAGE
    submitFile(){
      this.file = this.$refs.file.files[0];

      var formData = new FormData();

      formData.append('file', this.file);

      axios.post('./src/php/costimizer_collab_image_upload.php', formData, {
        header:{
          'Content-Type' : 'multipart/form-data'
        }
      }).then((response)=>{
        if(response.data.image == '')
        {
          this.errorAlert = true;
          this.successAlert = false;
          this.errorMessage = response.data.message;
          this.successMessage = '';
          this.uploadedImage = '';
        }
        else
        {
          this.errorAlert = false;
          this.successAlert = true;
          this.errorMessage = '';
          this.successMessage = response.data.message;
          let image_html = "<img src='"+response.data.image+"' class='img-thumbnail' width='200' />";
          this.imgName = response.data.image;
          this.uploadedImage = image_html;
          this.$refs.file.value = '';
        }
      });
    },

    /*
      Handles a change on the file upload
    */
    handleFileUpload(){
      this.file = this.$refs.file.files[0];

    },
    //   AUTOCOMPLETE COUNTRIES

    searchLocation(input) {

      if (input.length < 1) { return [] }
      return countries.filter(countries => {

        return countries.toLowerCase()
            .includes(input.toLowerCase())
      })
    },
    handleSubmit(result) {
      this.supplierLocation = result
    },

    //  MANAGE PARTS
    fetchAllDataPart: function () {
      axios.post('/src/php/costimizer_collab_manage_parts.php', {
        action: 'fetchallPart', projectNo: this.$route.query.projectNo, wpID: this.$route.query.wpID, wpHash: this.$route.query.wpHash,
        ceID: this.$parent.$auth.user.sub, partEstCost:0,
        subassyID: this.subassyID,
        userRole : this.$route.query.userRole
      }).then((response) => {
        this.allDataPart = response.data;


      });
    },

    openPartInfo: function () {
      this.mouseY = event.clientY+'px';
      this.partInfoModal = true;
    },



    openModelPart: function () {
      this.partName = '';
      this.partRef = '';
      this.partDesc = '';
      this.partTypeE = '';
      this.partWeight	 = '';
      this.partMainMat = '';
      this.partComplexity = '';
      this.partQty =  '1';
      this.partSource = 'Internal'
      this.supplierLocation = '';
      this.supplierName = '';
      this.partEstCost = 0;
      this.actionButton = "Save";
      this.dynamicTitle = "Add Part";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },
    submitDataPart: function () {
      if (this.partName != '') {
        if (this.actionButton == 'Save') {
          if (!this.partEstCost)  {
            this.partEstCost = 0;
          }
          if (!this.partComplexity)  {
            this.partComplexity = 1
          }
          if (!this.partMainMat)  {
            this.partMainMat = ''
          }
          if (!this.partWeight)  {
            this.partWeight = 0
          }
          this.file='';
          this.previewImage = null;
          this.successAlert =false;
          this.myModel = false;
          axios.post('/src/php/costimizer_collab_manage_parts.php', {
            action: 'insertPart',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            subassyID: this.subassyID,
            wpHash: this.$route.query.wpHash,
            partQty: this.partQty,
            ceID: this.$parent.$auth.user.sub,
            partName: this.partName,
            partRef: this.partRef,
            supplierName: this.supplierName,
            supplierLocation: this.supplierLocation,
            partDesc: this.partDesc,
            partTypeE: this.partTypeE,
            partWeight: this.partWeight,
            partMainMat: this.partMainMat,
            partComplexity:this.partComplexity,
            imgName: this.imgName,
            partEstCost: this.partEstCost,
            partSource:  this.partSource

          }).then( (response)=>{
            parent.myModel = false;
            this.fetchAllDataPart();
            this.partName = '';
            this.previewImage = '';
            // this.partName = '';
            // this.partDesc = '';

            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if (this.actionButton == 'Update') {
          axios.post('/src/php/costimizer_collab_manage_parts.php', {
            action: 'updatePart',
            wpID: this.wpID,
            partID: this.partID,
            partQty: this.partQty,
            ceID: this.$parent.$auth.user.sub,
            partName: this.partName,
            partRef: this.partRef,
            supplierName: this.supplierName,
            supplierLocation: this.supplierLocation,
            partDesc: this.partDesc,
            partTypeE: this.partTypeE,
            partWeight: this.partWeight,
            partMainMat: this.partMainMat,
            partComplexity:this.partComplexity,
            imgName: this.imgName,
            partEstCost: this.partEstCost,
            partSource:  this.partSource
          }).then((response) => {
            this.myModel = false;
            this.fetchAllDataPart();
            this.wpID = '';
            this.partName = '';
            this.previewImage = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Fill All Field");
      }
    },
    fetchDataPart: function (partID) {
      let self = this
      axios.post('/src/php/costimizer_collab_manage_parts.php', {
        action: 'fetchSinglePart',
        partID: partID
      }).then(function (response) {
        self.wpID = response.data.wpID;
        self.partName = response.data.partName;
        self.partRef = response.data.partRef;
        self.hiddenId = response.data.ID;
        self.partQty = response.data.partQty;
        self.imgName = response.data.imgName;
        self.previewImage = response.data.imgName;
        self.partEstCost = response.data.partEstCost;
        self.supplierName=  response.data.supplierName;
        self.supplierLocation = response.data.supplierLocation;
        self.partDesc = response.data.partDesc;
        self.partTypeE = response.data.partTypeE;
        self.partWeight = response.data.partWeight;
        self.partMainMat = response.data.partMainMat;
        self.partComplexity = response.data.partComplexity;
        self.partID = response.data.partID;
        self.mouseY = '-'+event.clientY+'px';
        self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
        self.partSource = response.data.partSource;
      });
    },
    deleteDataPart: function (partID) {
      if (confirm("Are you sure you want to remove this data?"+parent.partID)) {
        axios.post('/src/php/costimizer_collab_manage_parts.php', {
          action: 'deletePart',
          partID:  partID
        }).then( (response) => {
          this.fetchAllDataPart();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },

    /// PASTE FUNCTIONS


    clipPart: function (partID, partName) {
      this.$store.state.clipboard.partID = partID;
      this.$store.state.clipboard.partName = partName;

   // Add clipPart to clip DB
      axios.post('/src/php/costimizer_collab_manage_clipboard.php', {
        action: 'clipPart',
        ceID: this.$parent.$auth.user.sub,
        userRole: this.userRole,
        clippedPartID: partID,
        clippedPartName: partName
      }).then((response) => {
        if ( response.data.message ) {
         //  alert(response.data.message);
          response.data.message = '';
        }
      });

    },

    pastePart: function (pastePartID, targetPartID,type) {
    //  alert(pastePartID)
      axios.post('/src/php/costimizer_collab_manage_parts.php', {
        action: 'pastePart',
        actionType: type,
        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        wpHash: this.$route.query.wpHash,
        subassyID: this.subassyID,
        pastePartID: pastePartID,    // changed from partID   220906
        targetPartID: targetPartID

      }).then((response) => {
        parent.myModel = false;
        this.fetchAllDataPart();
        // this.commodityName = '';
        // Alert to replace by Toast
        if ( response.data.message ) {
          // alert(response.data.message);
          response.data.message = '';
        }
      });
    },


  },

  created:function() {

    this.compUID = uuidv4();
    this.compUID = this.compUID.replace(/-/g, '');
    this.compUID = 'a' + this.compUID;
    this.compUID = this.compUID.substring(0, 10);
    this.hcompUID = '#' + this.compUID;
    this.mhcompUID = 'mh' + this.compUID;
    this.mchcompUID = '#mc' + this.compUID;
    this.mccompUID = 'mc' + this.compUID;

    this.fetchAllDataPart();

    // FALL BACK IF Compute is no reactive
    this.getPartCostTrigger();
    this.timer = setInterval(this.getPartCostTrigger, 5000000);


  },

  computed: {


 /*   partEstCost: function () {
      let materialCoeff = 0
      switch (this.partMainMat) {
        case 'Metal':
          materialCoeff = 1.7;
        break;
        case 'Polymer':
          materialCoeff = 2.8;
          break;
        case 'Metal and Polymer':
          materialCoeff = 2.3;
          break;
        case 'Electronic':
          materialCoeff = 7.0;
          break;
        case 'Fabrics':
          materialCoeff = 1.5;
          break;
        case 'Composites':
          materialCoeff = 4.5;
          break;
      }

      return (Math.round((materialCoeff * this.partWeight/1000* this.partComplexity) * 100) / 100);
    },*/


    sumPartCost() {
      let sumPartCostVal = 0
      let partCostVal = 0
      let self = this


      for (let i = 0; i < this.allDataPart.length; i++) {


        for (let y = 0; y < this.$store.state.processSum.length; y++) {

          if (this.$store.state.processSum[y].wpID == this.allDataPart[i].wpID && this.$store.state.processSum[y].partID == this.allDataPart[i].partID) {


            if (this.allDataPart[i].partSource == 'External') {
              partCostVal = partCostVal + Math.round(this.$store.state.processSum[y].procCostSum * (1 * this.$store.state.project.sgaOH) * (1 + this.$store.state.project.marginAddedValue) * 100) / 100

            } else {
              partCostVal = partCostVal + this.$store.state.processSum[y].procCostSum;

            }

          }
        }
        for (let y = 0; y < this.$store.state.material.length; y++) {

          if (this.$store.state.material[y].partID == this.allDataPart[i].partID) {


            if (this.allDataPart[i].partSource == 'External') {
              partCostVal = partCostVal + Math.round(this.$store.state.material[y].materialCost * (1 * this.$store.state.project.sgaOH) * (1 + this.$store.state.project.marginMaterial) * 100) / 100;

            } else {
              partCostVal = partCostVal + this.$store.state.material[y].materialCost;

            }

          }
        }
        sumPartCostVal = sumPartCostVal + (partCostVal * this.allDataPart[i].partQty)
        // partCostVal > 0.05 to avoid wrong couning if
        if (this.allDataPart[i].partEstCost > 0 &&  Math.round(partCostVal*100)/100 == 0) {
          sumPartCostVal = sumPartCostVal + parseFloat(this.allDataPart[i].partEstCost)* this.allDataPart[i].partQty;
        }
        partCostVal = Math.round(partCostVal * 100) / 100;
        self.allDataPart[i].partCost = partCostVal
        partCostVal = 0

        sumPartCostVal = Math.round((sumPartCostVal + this.trigger) * 100) / 100
        sumPartCostVal = Math.round((sumPartCostVal - this.trigger) * 100) / 100


        sumPartCostVal = Math.round(sumPartCostVal * 100) / 100

        // move result for all processes below subassembly or main assembly to store

        if (sumPartCostVal != 0) {
          this.$store.commit('addPartSumCost', {
            wpID: this.wpID,
            projectNo: this.$route.query.projectNo,
            subassyID: this.allDataPart[i].subassyID,
            partTotalCost: sumPartCostVal
          });

        }

      }
      // FALL BACK IF Compute is no reactive




      return (sumPartCostVal)
    },


  }
}
</script>

<style scoped>

.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.accordion {
  padding-left: 10px;
}

.accordion-item {
  margin-bottom:2px;
}

.accordion-header {
  background-color: #EE6A22;
  color: #E7E9DF;
  padding-left: 10px;
}
.accordion-button  {
  background-color: #EE6A22;
  color: #E7E9DF;
  padding-top:5px;
  padding-bottom:5px;
}



table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

 .modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  font-size: medium;
}


</style>