<template>
<div>


<div class="container-fluid  " style="margin-top: 100px">
     <div class="row flex-nowrap min-vh-100">
       <div class="col-auto  sticky-top "  style="width: 15%">
         <div class="d-flex flex-sm-column flex-row flex-nowrap  align-items-start sticky-top">
           <a href="/" class="d-flex align-items-start pb-3 mb-md-0 me-md-auto text-white text-decoration-none">

             <span class="fs-5 d-none d-sm-inline"></span>
           </a>
           <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start" id="menu">
             <li class="nav-item">
               <a href="#" class="nav-link align-items-start px-1">
                 <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home {{userRole}}</span>
               </a>
             </li>
             <li v-if="userRole === 'admin'" class="nav-item">
               <a href="#" class="nav-link align-items-start px-1">
                 <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline" >WP - System View</span>
               </a>
             </li>
             <li>
               <a href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                 <i class="fs-4 bi-clipboard-plus"></i> <span class="ms-1 d-none d-sm-inline"><strong>Local Clip-Board</strong></span> </a>
               <ul class="collapse.show  nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0">
                     <i class="fs-4 bi-cloud-arrow-down" style="color:#FF7F50" @click="syncClipBoards"></i> <span class="ms-1 d-none d-sm-inline"><strong @click="syncClipBoards">Sync!</strong></span> </a>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Assembly</span> </a>
                   <ul v-if="$store.state.clipboard.assemblyID.length > 2"  >
                     <li  style="font-size: small; color:orangered; text-indent:5%">{{$store.state.clipboard.assemblyName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Part</span> </a>
                   <ul v-if="$store.state.clipboard.partID.length > 2"  >
                     <li  style="font-size: small; color:orangered ; text-indent:5%">{{$store.state.clipboard.partName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Material</span> </a>
                   <ul v-if="$store.state.clipboard.materialID.length > 2"  >
                     <li  style="font-size: small; color:orangered; text-indent:5%">{{$store.state.clipboard.materialName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Process</span> </a>
                   <ul v-if="$store.state.clipboard.processID.length > 2"  >
                     <li  style="font-size: small; color:orangered; text-indent:5%">{{$store.state.clipboard.processName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Assy-Process</span> </a>
                   <ul v-if="$store.state.clipboard.assyprocessID.length > 2"  >
                     <li  style="font-size: small; color:orangered; text-indent:5%">{{$store.state.clipboard.assyprocessName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Equipment</span> </a>
                   <ul v-if="$store.state.clipboard.equipmentID.length > 2"  >
                     <li  style="font-size: xx-small; color:orangered; text-indent:5%">{{$store.state.clipboard.equipmentName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap" style="text-indent:10%">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Commodity</span> </a>
                   <ul v-if="$store.state.clipboard.commodityID.length > 2"  >
                     <li  style="font-size: small; color:orangered; text-indent:5%">{{$store.state.clipboard.commodityName}}</li>
                   </ul>
                 </li>
                 <li>
               </ul>
             </li>
             <!-- <li>
             <a href="#submenu2" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
               <i class="fs-4 bi-clipboard-plus" style="color:#7FFF00" ></i> <span class="ms-1 d-none d-sm-inline"><strong>Linked Clip-Board</strong></span> </a>
             <ul class="collapse  nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
               <li class="w-100 text-wrap" style="text-indent:10%">
                 <a href="#" class="nav-link px-0">
                 <i class="fs-4 bi-cloud-arrow-down" style="color:#FF7F50" @click="syncClipBoards"></i> <span class="ms-1 d-none d-sm-inline"><strong @click="syncClipBoards">Sync!</strong></span> </a>
               </li>
             </ul>
             </li> -->
             <li>
               <a href="#" class="nav-link px-0 align-items-start px-1">
                 <i class="fs-4 bi-table" ></i> <span class="ms-1 d-none d-sm-inline"><strong>Informations</strong></span></a>
             </li>

             <li>
               <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0 align-left px-1">
                 <i class="fs-4 bi-bookmark"></i> <span class="ms-1 d-none d-sm-inline"><strong>Favorites</strong></span> </a>
               <ul class="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                 <li class="w-100">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Equipment</span></a>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Processes</span></a>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Parts</span></a>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Materials</span></a>
                 </li>
               </ul>
             </li>
             <li>
               <a href="#" class="nav-link px-0 align-items-start px-1">
                 <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"><strong>User Profil</strong></span> </a>
             </li>
           </ul>
           <hr>
           <div class="dropdown pb-4">
             <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">

               <div v-for="(value, index) in namespace" v-bind:key="value">
                 <div v-if="index==='https://www.costimizer.de/picture'"><img
                     v-bind:src="value"
                     alt="User's profile picture"
                     class="rounded-circle img-fluid profile-picture" width="50" v-bind:key="value"
                 /></div>
               </div>
               <span class="d-none d-sm-inline mx-1">{{ $auth.user.name }}</span>
             </a>
             <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
               <li><router-link to="/profile" class="dropdown-item" />Profile</li>
               <li><a class="dropdown-item" href="#">Logout</a></li>
               <li>
                 <hr class="dropdown-divider">
               </li>
               <li><a class="dropdown-item" href="#">Sign out</a></li>
             </ul>
           </div>
         </div>
       </div>
       <div class="col py-3">
         <h5>Project No.: {{projectNo}}__Workpackage No.:  {{wpID}}__Workpackage: {{wpName}} </h5>
         <WorkpackageSCA_Header  v-bind:namespace="namespace" />

       </div>
     </div>
 </div>

</div>
</template>

<script>

import WorkpackageSCA_Header from "@/components/WorkpackageSCA_Header";

const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

export default {
  name: "WorkpackageSCA, WorkpackageSCA_Header",
  components: {WorkpackageSCA_Header},
  data() {
    return {
      projectno: null,
      wpID: null,
      wpName: null,
      userRole:'',
      namespace : (this.$parent.$auth.user),
      trigger: 0,
      costimizerVersion: '',
      version: 'V01.0155',
  };
  },


  methods: {
    // CHECK FOR UPDATES
    triggerUpdate: function () {
      this.trigger++;
        axios.post('/src/php/costimizer_collab_updatecheck.php', {
          action: 'checkupdate'
        }).then((response) => {
          if (this.version != response.data[0].costimizerVersion) {
           this.$toasted.show("Attention, new Costimizer Version available, it is recommended to reload the application!", {
              theme: "bubble",
              position: "top-right",
              fullWidth: true,
              duration: 5000,
              icon: 'info',
                  action : {
                    text : 'Ok',
                    onClick : (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  },
            }
            );
          }
            this.trigger = 0;
        });

    },

    syncClipBoards: function () {

      // get current clipboard
      axios.post('/src/php/costimizer_collab_manage_clipboard.php', {
        action: 'syncClipBoards',
        ceID: this.$parent.$auth.user.sub,
        userRole: this.userRole,
      }).then((response) => {
        if ( response.data.message ) {
         // alert(response.data.clippedPartName);
          response.data.message = '';


          this.$store.state.clipboard.partID = response.data.clippedPartID;
          this.$store.state.clipboard.partName = response.data.clippedPartName;
          // this.$store.state.clipboard.assemblyID = response.data.clippedAssemblyID;
          //this.$store.state.clipboard.assemblyName = response.data.clippedAssemblyName;
          this.$store.state.clipboard.materialID = response.data.clippedMaterialID;
          this.$store.state.clipboard.materialName = response.data.clippedMaterialName;
          this.$store.state.clipboard.processID = response.data.clippedProcessID;
          this.$store.state.clipboard.processName = response.data.clippedProcessName;
          this.$store.state.clipboard.assyprocessID = response.data.clippedAssyProcessID;
          this.$store.state.clipboard.assyprocessName = response.data.clippedAssyProcessName;
          this.$store.state.clipboard.equipmentID = response.data.clippedEquipmentID;
          this.$store.state.clipboard.equipmentName = response.data.clippedEquipmentName;
          this.$store.state.clipboard.commodityID = response.data.clippedCommodityID;
          this.$store.state.clipboard.commodityName = response.data.clippedCommodityName;
        }
      });
    }
  },
  mounted: function () {
    window.scrollTo(0,0)
    this.projectno = this.$route.query.projectno
    this.wpID = this.$route.query.wpID
    this.wpName = this.$route.query.wpName

    this.triggerUpdate();
    this.timer = setInterval(this.triggerUpdate, 200000);
    this.userRole = this.namespace['https://www.costimizer.de/role'];

  }

  }
</script>

<style scoped>

.col-sm-auto
{
  background-color: #5A5C4F;
  color: #E7E9DF;
}

.d-flex
{
  background-color: #5A5C4F;
  color: #E7E9DF;
}


.nav .nav-item .nav-link
{
  color: #E7E9DF;
}

li a
{
  color: #E7E9DF;
}



</style>