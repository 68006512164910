<template>
<div>
  <div class="container" id="Project_Header">
    <br />
    <h3 align="center">Aktuell verfügbare Projekte</h3>
    <br />
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-6">
            <h3 class="panel-title">Sample Data</h3>
          </div>
          <div class="col-md-6" align="right">
            <input type="button" class="btn btn-success btn-xs" @click="fetchAllData" value="Add" />
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>PL</th>
              <th >Description</th>
              <th>Open</th>
              <th>Unassign</th>
            </tr>
            <tr v-for="row in allData" v-bind:key="row">
              <td>{{ row.projectNo }}</td>
              <td>{{ row.projectName }}</td>
              <td>{{ row.projectStart }}</td>
              <td>{{ row.projectEnd }}</td>
              <td>{{ row.projectLeader }}</td>
              <td>{{ row.projectDesc }}</td>
              <td><router-link :to="{path: '/workpackages',  query:{projectNo: row.projectNo}}" class="nav-link">Open Project</router-link></td>

              <td><button type="button" name="edit" class="btn btn-primary btn-xs edit" @click="fetchData(row.ID)">Edit</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
 </div>

  <div v-if="myModel">
    <transition name="model">
      <div class="modal-mask" v-bind:style="{top: mouseY}" >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label>Enter First Name</label>
                  <input type="text" class="form-control" v-model="projectNo" />
                </div>
                <div class="form-group">
                  <label>Enter Last Name</label>
                  <input type="text" class="form-control" v-model="projectName" />
                </div>
                <br />
                <div align="center">
                  <input type="text" v-model="hiddenId" />
                  <input type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitData" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</div>
  </template>

<script>

const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

export  default {
  name: "Project_Header",



  data: function () {
    return {allData:{}, myModel:false, actionButton:'Insert', dynamicTitle:'Add Data', projectNo:'', projectName:''};
  },
  methods: {
    fetchAllData:function() {
      axios.post('/src/php/costimizer_collab_manage_project_entry.php', {
        action:'fetchallProjects'
      }).then((response)=>{
        this.allData=response.data;
      });
    },

    openModel:function(){
      this.projectNo = '';
      this.projectName = '';
      this.actionButton = "Insert";
      this.dynamicTitle = "Add Data";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },
    submitData:function(){
      if(this.projectNo != '' && this.projectName != '')
      {
        if(this.actionButton == 'Insert')
        {
          axios.post('/src/php/costimizer_collab_manage_project_entry.php', {
            action:'insert',
            projectNo:this.projectNo,
            projectName:this.projectName
          }).then(function(response){
            this.myModel = false;
            this.fetchAllData();
            this.projectNo = '';
            this.projectName = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if(this.actionButton == 'Update')
        {
          axios.post('/src/php/costimizer_collab_manage_project_entry.php', {
            action:'update',
            projectNo : this.projectNo,
            projectName: this.projectName,
            hiddenId : this.hiddenId
          }).then(function(response){
            this.myModel = false;
            this.fetchAllData();
            this.projectNo = '';
            this.projectName = '';
            this.hiddenId = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      }
      else
      {
        alert("Fill All Field");
      }
    },
    fetchData:function(id){
      let self = this
      axios.post('/src/php/costimizer_collab_manage_project_entry.php', {
        action:'fetchSingle',
        id:id
      }).then(function(response){
        self.projectNo = response.data.projectNo;
        self.projectName = response.data.projectName;
        self.hiddenId = response.data.ID;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
      });
    },
    deleteData:function(id){
      if(confirm("Are you sure you want to remove this data?"))
      {
        axios.post('/src/php/costimizer_collab_manage_project_entry.php', {
          action:'delete',
          id:id
        }).then(function(response){
          this.fetchAllData();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    }
  },
  created:function(){
    this.fetchAllData();
  }

 }
</script>

<style scoped>
table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

 .modal-mask {
   position: absolute;
   z-index: 9998;
   top: 20%;
   left: 10%;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

</style>