<template>
  <div>


    <div class="accordion accordion-flush" :id="compUID">
      <div class="accordion-item">

        <div class="row justify-content-start">
          <div class="col-11">
            <h2 class="accordion-header" :id="mhcompUID">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="margin-right:10px"  fill="currentColor" class="bi bi-diagram-3-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"/>
                </svg>
                SUB-ASSEMBLIES  (This Assembly Level Cost: <strong> {{sumSubAssyCost}}€</strong>, incl. Overhead)
              </button>
            </h2>
          </div>
          <div class="col-1 my-auto" style="">
            <div v-if="userRole != 'costengineer' ">
            <button v-if="(userRole!= 'client' && $route.query.wpStatus == 'working') || (userRole == 'admin' || userRole == 'projectmanager')" type="button" class="btn btn-link btn-sm" @click="openModelSubassys(parentassyID)" value="Add">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="margin-right:10px" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </button>
              </div>
          </div>
        </div>

        <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID">

          <div class="accordion accordion-flush" v-for="row in allDataSubassy" v-bind:key="row" :id="row.subassyID" >
            <div class="accordion-item"  style="margin-left:15px; margin-bottom: 2px;">
              <div class="row justify-content-start">
                <div class="col-10" >
                  <h2 class="accordion-header" :id="'flush'+row.subassyID">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+row.subassyID" aria-expanded="false" :aria-controls="'flush-collapse'+row.subassyID" style="background-color: #ACAEA0; color: #5A5C4F">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  style="margin-right:10px" fill="currentColor" class="bi bi-diagram-2-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1z"/>
                      </svg>

                        <svg  @mouseover="showImageModal(row.subassyImgPath1, row.subassyName)"   xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                          <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
                          <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                        <img :src='row.subassyImgPath1' width="5%" alt="noPic"   style="padding-right:10px"   />
                      ASSEMBLY NAME: {{row.subassyName}}, REF: {{row.subassyRef}}, [Qty:  {{row.subassyQty}}]
                    </button>
                  </h2>
                </div>
                <div class="col-2 my-auto" style="">

                  <button   v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')"  type="button" class="btn btn-link btn-sm" @click="fetchDataSubassys(row.subassyID)" value="Edit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </button>

                  <button v-if="userRole!= 'client'" type="button" class="btn btn-link btn-sm" @click="deleteDataSubassys(row.subassyID)" value="Delete">
                    <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>

                </div>
              </div>
              <div :id="'flush-collapse'+row.subassyID" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+row.subassyID" :data-bs-parent="'#'+row.subassyID">

                <Assemblies v-bind:parentassyID="row.subassyID" v-bind:wpStatus="$route.query.wpStatus"  v-bind:namespace="namespace"/>
                <Parts v-bind:subassyID="row.subassyID"  v-bind:wpStatus="$route.query.wpStatus" v-bind:namespace="namespace"/>
                <Assemblyproc v-bind:subassyID="row.subassyID" v-bind:assyRoot="assyRoot"  v-bind:wpStatus="$route.query.wpStatus" v-bind:namespace="namespace"/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="myModel">
      <transition name="model">
        <div class="modal-mask" v-bind:style="{top: mouseY}" >
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                  <h4 class="modal-title">{{ dynamicTitle }}</h4>
                </div>
                <div class="modal-body">
                  <div>
                <div v-if="!successAlert && previewImage"
                     class="imagePreviewWrapper"
                     :style="{ 'background-image': `url(${previewImage})` }"
                     @click="selectImage">
                </div>

                <div v-if="successAlert && previewImage"
                     class="imagePreviewWrapper"
                     :style="{ 'background-image': `url(${previewImage})` }"
                >
                </div>


                <input v-if="!successAlert"
                       ref="file"
                       type="file"
                       id = "file"
                       v-on:change="handleFileUpload()"
                       @input="pickFile" />
              </div>
              <button v-if="!successAlert" v-on:click="submitFile()">SAVE PICTURE</button>



                  <div class="form-group">
                    <label>Enter Subassembly Name</label>
                    <input type="text" class="form-control" v-model="subassyName" required/>
                  </div>
                <div class="form-group">
                  <label>Subassembly Ref-ID</label>
                  <input type="text" class="form-control" v-model="subassyRef" />
                </div>
                  <div class="form-group">
                    <label>Enter Quantity of Subassemblies</label>
                    <input type="number" min="1" max="999" step="1" class="form-control" v-model="subassyQty" required/>
                  </div>
                  <div class="form-group">
                    <label>Enter Supplier Location</label>
                    <autocomplete  id="supplierLocation"  ref="autocomplete" :search="searchLocation"  placeholder="Search Location"   default-value="Mexico" @submit="handleSubmit"/>
                    <!--                  <input type="text" class="form-control" v-model="supplierLocation" placeholder="optional"/>-->
                  </div>
                  <div class="form-group">
                    <label>Enter Supplier Name</label>
                    <input type="text" class="form-control" v-model="supplierName" placeholder="optional"/>
                  </div>
                  <div class="form-group">
                    <label>Enter Subassembly Description</label>
                    <textarea type="text" class="form-control" v-model="subassyDesc" rows="3" placeholder="optional"></textarea>
                  </div>
                  <br />
                  <div align="center">
                    <input type="hidden" v-model="hiddenId" />
                    <input type="hidden" v-model="this.subassyID" />
                    <input type="hidden" v-model="this.parentassyID" />
                    <input type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataSubassys" />
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>


    <div v-if="myModelPic"  @click="closeImageModal">
      <transition name="model">
        <div class="modal-mask" v-bind:style="{top: mouseY}" >
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="myModelPic=false"><span aria-hidden="true">&times;</span></button>
                  <h4 class="modal-title">{{dynamicTitlePic}}</h4>
                </div>

                <img :src='partPath' width="100%" alt="partpic"   style="padding:10px"  />


              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>


  </div>
</template>

<script>

import Autocomplete from "@trevoreyre/autocomplete-vue";

const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

// import the style
import '@trevoreyre/autocomplete-vue/dist/style.css'

import Parts from "@/components/Parts";
import Assemblyproc from "@/components/Assembly_Proc";
import Assemblies from "@/components/Assembly_Component";
import {v4 as uuidv4} from "uuid";
import countries from "@/assets/datalists/countries.json";


export  default {
  name: "Assemblies",

  components: {Assemblies, Assemblyproc, Parts, Autocomplete},
  props: {
    parentassyID: String,
    wpStatus: String,
    namespace: Array,
  },

  data: function () {
    return {
      allDataSubassy: {},
      myModel: false,
      actionButton: 'Insert',
      dynamicTitle: 'Add Data',
      subassyName: {},
      subassyID: '',
      subassyRef: '',
      subassyImgPath1:'',
      subassyImgPath2: '',
      projectNo: this.$route.query.projectno,
      wpHash: this.$route.query.wpHash,
      wpID: this.$route.query.wpID,
      subassyDesc: '',
      supplierLocation: 'Mexico',
      supplierName: '',
      assyRoot: 'ROOT',
      subassyQty: '1',
      wpName: this.$route.query.wpName,
      trigger: 0,
      userRole : this.namespace['https://www.costimizer.de/role'],
      previewImage: null, file:'',  successAlert:false, errorAlert:false,  uploadedImage:'', imgName:'' , errorMessage: '', successMessage:'',
      mouseY: 0
    };
  },


  methods: {
//   AUTOCOMPLETE COUNTRIES

    triggerCost() {
      this.trigger++;

    },

    // SELECT IMAGE
    selectImage () {
      this.$refs.file.click()
    },
    pickFile () {
      let input = this.$refs.file
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },


    //  Save IMAGE
    submitFile(){
      this.file = this.$refs.file.files[0];

      var formData = new FormData();

      formData.append('file', this.file);

      axios.post('./src/php/costimizer_collab_image_upload.php', formData, {
        header:{
          'Content-Type' : 'multipart/form-data'
        }
      }).then((response)=>{
        if(response.data.image == '')
        {
          this.errorAlert = true;
          this.successAlert = false;
          this.errorMessage = response.data.message;
          this.successMessage = '';
          this.uploadedImage = '';
        }
        else
        {
          this.errorAlert = false;
          this.successAlert = true;
          this.errorMessage = '';
          this.successMessage = response.data.message;
          let image_html = "<img src='"+response.data.image+"' class='img-thumbnail' width='200' />";
          this.imgName = response.data.image;
          this.uploadedImage = image_html;
          this.$refs.file.value = '';
        }
      });
    },

    /*
      Handles a change on the file upload
    */
    handleFileUpload(){
      this.file = this.$refs.file.files[0];

    },



    searchLocation(input) {

      if (input.length < 1) {
        return []
      }
      return countries.filter(countries => {

        return countries.toLowerCase()
            .includes(input.toLowerCase())
      })
    },
    handleSubmit(result) {
      this.supplierLocation = result
    },

    showImageModal: function(imgName, subassyName) {
      this.dynamicTitlePic = subassyName;
      this.partPath = imgName;
      this.myModelPic = true;
    },
    closeImageModal: function() {

      this.myModelPic = false;
    },


    //  MANAGE SUBASSEMBLIES
    fetchAllDataSubassys: function () {
      axios.post('/src/php/costimizer_collab_manage_subassys.php', {
        action: 'fetchallSubassys',
        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        wpHash: this.$route.query.wpHash,
        ceID: this.$parent.$auth.user.sub,
        parentassyID: this.parentassyID,
        userRole: this.$route.query.userRole
      }).then((response) => {
        this.allDataSubassy = response.data;
      });
    },

    openModelSubassys: function (parentassyID) {
      this.previewImage =false;
      this.subassyName = '';
      this.subassyRef = '';
      this.subassyDesc = '';
      this.supplierLocation = '';
      this.supplierName = '';
      this.subassyQty = '1';
      this.actionButton = "Save";
      this.dynamicTitle = "Add Subassys";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
      this.parentassyID = parentassyID;
    },
    submitDataSubassys: function () {
      if (this.subassyName != '') {
        if (this.actionButton == 'Save') {
          this.myModel = false;
          axios.post('/src/php/costimizer_collab_manage_subassys.php', {
            action: 'insertSubassys',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            subassyQty: this.subassyQty,
            parentassyID: this.parentassyID,
            wpHash: this.$route.query.wpHash,
            subassyName: this.subassyName,
            subassyRef: this.subassyRef,
            subassyImgPath1: this.imgName,
            subassyImgPath2: this.imgName,
            supplierName: this.supplierName,
            supplierLocation: this.supplierLocation,
            subassyDesc: this.subassyDesc,
            ceID: this.$parent.$auth.user.sub,

          }).then((response) => {
            parent.myModel = false;
            this.fetchAllDataSubassys();
            // this.subassyName = '';
            // this.subassyDesc = '';
            if ( response.data.message ) {
               alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if (this.actionButton == 'Update') {
          axios.post('/src/php/costimizer_collab_manage_subassys.php', {
            action: 'updateSubassys',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            parentassyID: this.parentassyID,
            subassyID: this.subassyID,
            subassyImgPath1: this.imgName,
            subassyImgPath2: this.imgName,
            wpHash: this.$route.query.wpHash,
            subassyQty: this.subassyQty,
            subassyName: this.subassyName,
            subassyRef: this.subassyRef,
            supplierName: this.supplierName,
            supplierLocation: this.supplierLocation,
            subassyDesc: this.subassyDesc,
            ceID: this.$parent.$auth.user.sub

          }).then((response)=>{
            this.myModel = false;
            this.fetchAllDataSubassys();
            this.wpID = '';
            this.subassyName = '';
            this.hiddenId = '';
            if ( response.data.message ) {
              alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Fill all necessary Fields");
      }
    },
    fetchDataSubassys: function (subassyID) {
      let self = this
      axios.post('/src/php/costimizer_collab_manage_subassys.php', {
        action: 'fetchSingleSubassys',
        subassyID: subassyID
      }).then(function (response) {
        self.projectNo = response.data.projectNo;
        self.wpID = response.data.wpID;
        self.parentassyID = response.data.parentassyID;
        self.parentassyID = response.data.parentassyID;
        self.subassyID = response.data.subassyID;
        self.subassyImgPath1 = response.data.subassyImgPath1;
        self.previewImage = response.data.subassyImgPath1;
        self.imgName = response.data.subassyImgPath1;
        self.subassyImgPath2 = response.data.subassyImgPath2;
        self.wpHash = response.data.wpHash;
        self.subassyQty = response.data.subassyQty;
        self.subassyName = response.data.subassyName;
        self.subassyRef = response.data.subassyRef;
        self.supplierName = response.data.supplierName;
        self.supplierLocation = response.data.supplierLocation;
        self.subassyDesc = response.data.subassyDesc;
        self.ceID = response.data.ceID;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';


      });
    },
    deleteDataSubassys: function (subassyID) {
      if (confirm("Are you sure you want to remove this data?" + parent.subassyID)) {
        axios.post('/src/php/costimizer_collab_manage_subassys.php', {
          action: 'deleteSubassys',
          subassyID: subassyID
        }).then((response) => {
          this.fetchAllDataSubassys();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    }
  },

  created: function () {
    this.compUID = uuidv4();
    this.compUID = this.compUID.replace(/-/g, '');
    this.compUID = 'a' + this.compUID;
    this.compUID = this.compUID.substring(0, 10);
    this.hcompUID = '#' + this.compUID;
    this.mhcompUID = 'mh' + this.compUID;
    this.mchcompUID = '#mc' + this.compUID;
    this.mccompUID = 'mc' + this.compUID;

    // console.log("This component's unique id is: " + this.compUID);

    this.fetchAllDataSubassys();

    this.triggerCost();
    this.timer = setInterval(this.triggerCost, 5000000);



  },

  computed: {
    sumSubAssyCost() {


      let sumSubAssyCostVal = 0


      // Calculate CURRENT SUB ASSY COST AND ADD TO WP COST

        for (let y = 0; y < this.$store.state.assyProcessSum.length; y++) {


          if (this.$store.state.assyProcessSum[y].wpID == this.wpID && this.$store.state.assyProcessSum[y].subassyID == this.parentassyID) {
            sumSubAssyCostVal = sumSubAssyCostVal + this.$store.state.assyProcessSum[y].assyProcCostSum;

          }
          if (this.$store.state.assyProcessSum[y].wpID == this.wpID && this.parentassyID =='ROOT' && this.$store.state.assyProcessSum[y].subassyID == 'NONE') {

            sumSubAssyCostVal = sumSubAssyCostVal + this.$store.state.assyProcessSum[y].assyProcCostSum;

          }
        }
        for (let j = 0; j < this.$store.state.partSumCost.length; j++) {

          if (this.$store.state.partSumCost[j].wpID == this.wpID && this.$store.state.partSumCost[j].subassyID == this.parentassyID) {
            sumSubAssyCostVal = sumSubAssyCostVal + this.$store.state.partSumCost[j].partTotalCost;
          }
          if (this.$store.state.partSumCost[j].wpID == this.wpID && this.parentassyID =='ROOT' && this.$store.state.partSumCost[j].subassyID == 'NONE') {
            sumSubAssyCostVal = sumSubAssyCostVal + this.$store.state.partSumCost[j].partTotalCost;
          }
        }
    sumSubAssyCostVal = sumSubAssyCostVal + this.trigger
      sumSubAssyCostVal = sumSubAssyCostVal - this.trigger



        sumSubAssyCostVal = Math.round(sumSubAssyCostVal * 100) / 100
        // SENDE TO FINAL TO VUEX WP

        if (sumSubAssyCostVal != 0) {
          this.$store.commit('addWpSum', {
            wpID:  this.wpID,
            projectNo: this.$route.query.projectNo,
            subassyID: this.parentassyID,
            wpTotalCostSum: sumSubAssyCostVal
          });
        }

      return (sumSubAssyCostVal)

    }
  }
}

</script>

<style scoped>

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-header {
  background-color: #5A5C4F;
  color: #E7E9DF;
  padding-left: 10px;
}
.accordion-button  {
  background-color: #5A5C4F;
  color: #E7E9DF;
  padding-top:5px;
  padding-bottom:5px;
}




table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

.imagePreviewWrapper
{
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}



.modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}


</style>