<template>
<div>

  <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-fixed-top" style="z-index:9999 !important" >
    <div class="container-fluid">
      <div class="navbar-brand"><img src="../assets/costimizer.png"  width="200"/></div>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mr-auto">

            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item" v-if="$auth.isAuthenticated">
              <router-link to="/project"   class="nav-link">Projects</router-link>
            </li>
            <li class="nav-item" v-if="$auth.isAuthenticated && ($auth.user['https://www.costimizer.de/role'] === 'admin'||
                     $auth.user['https://www.costimizer.de/role'] === 'projectmanager' || $auth.user['https://www.costimizer.de/role'] === 'client')">
              <router-link to="/systemview"   class="nav-link">System-Manager</router-link>
            </li>
            <li class="nav-item" v-if="$auth.isAuthenticated && $auth.user['https://www.costimizer.de/role'] === 'admin'">
              <router-link to="/projectlab"   class="nav-link">Lab</router-link>
            </li>


            <li class="nav-item" v-if="!$auth.isAuthenticated && !$auth.loading"><a  href="#">
            <button
                id="qsLoginBtn"
                class="btn btn-primary btn-margin"
                @click.prevent="login">Login
            </button>
              </a>
            </li>
            <li class="nav-item dropdown" v-if="$auth.isAuthenticated">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                    <img
                        :src="$auth.user.picture"
                        alt="User's profile picture"
                        class="nav-user-profile rounded-circle"
                        width="40"
                    />



              </a>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdown" v-if="$auth.isAuthenticated">
              <li> <router-link to="/profile" class="dropdown-item dropdown-profile">
                      <font-awesome-icon class="mr-3" icon="user" />Profile
                    </router-link>
              </li>
              <li><a class="dropdown-item" href="#">Another action</a>
              </li>
              <li><hr class="dropdown-divider">
              </li>
              <li> <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
                <font-awesome-icon class="mr-3" icon="power-off" />Log out
               </a>
              </li>
            </ul>


          <ul class="navbar-nav d-md-none" v-if="!$auth.isAuthenticated && !$auth.loading">
            <button id="qsLoginBtn2" class="btn btn-primary btn-block" @click="login">Log in</button>
          </ul>

            </li>
          </ul>


        </div>
      </div>
    </nav>






  </div>
</template>

<script>



export default {
  name: "NavBar",


  data () {
    return {
      // namespace : (this.$auth.user),
      trigger:0,

    }
  },

  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    },
    triggerwpCost () {
      this.trigger++;
    },
    triggerUpdate: function () {
      this.trigger ++;
      if (this.trigger == 10000) {
        this.$toasted.show("Calculating", {
          theme: "bubble",
          position: "top-right",
          duration: 5000
        });
        this.trigger = 0;

      }
    }
  },
  created() {
    this.triggerwpCost();
    this.timer = setInterval(this.triggerwpCost, 5000000);
  },


};
</script>

<style>

.navbar {
  z-index: 1000;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}



#mobileAuthNavBar {
  min-height: 125px;
  justify-content: space-between;
}
</style>
