<template>
  <div class="text-center hero">
    <img class="mb-5 app-logo" src="/costimizer.png" alt="Costimizer Logo" width="30%" />
    <h2 class="mb-4">COSTIMIZER® Collaboration Tool</h2>
    <p >
      The COSTIMIZER® collaboration tool is a framework for remote cost analysis in teams and provides limited
      functionalaty in comparison to full equipped cost analysis tools.
      <a href="https://www.cardo.ag" >CARDO Holding AG</a>   &   <a href="https://www.fortechsolutions.de" >Fortech Solutions GmbH</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Hero"
};
</script>
